import { useState, useEffect, useRef } from 'react'
import { useOutletContext, useParams, useNavigate, Link } from 'react-router-dom'
import { CircularProgress, Grid, Typography, Box, Button, Divider  } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import SystemsDetailForm from '../components/SystemDetail/SystemsDetailForm';
import SystemsAccessLevelsGrid from '../components/SystemDetail/SystemsAccessLevelsGrid';
import SystemsAccessMethodsGrid from '../components/SystemDetail/SystemsAccessMethodsGrid';
import AccessLevelForm from '../components/SystemDetail/AccessLevelForm';
import AccessMethodForm from '../components/SystemDetail/AccessMethodForm';
import SystemsAccountsGrid from '../components/SystemDetail/SystemsAccountsGrid';
import { validateLoggedInUser } from '../components/Generic/ValidateLoggedInUser';
import KeyIcon from '@mui/icons-material/Key';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function SystemDetail(props) {
  const raiseAlert = useOutletContext();
  const navigate = useNavigate();
  let { id } = useParams(); 

  const [currentPage, setCurrentPage] = useState('accesslevelmethod')

  const accessLevelMethodRef = useRef(null);
  const IDPAccountsRef = useRef(null);
  const AccessAccountsRef = useRef(null);

  const [isLoadingSystem, setIsLoadingSystem] = useState(true)
  const [isLoadingPeople, setIsLoadingPeople] = useState(true)
  const [isLoadingAccessMethods, setIsLoadingAccessMethods] = useState(true)
  const [isLoadingAccessLevels, setIsLoadingAccessLevels] = useState(true)
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true)
  const [peopleFullNames, setPeopleFullNames] = useState([])

  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [ownerID, setOwnerID] = useState('');
  const [accessMethodRows, setAccessMethodRows] = useState([]);
  const [accessLevelRows, setAccessLevelRows] = useState([]);
  const [accountRows, setAccountRows] = useState([]);

  const [selectedAccessMethod, setSelectedAccessMethod] = useState(null);
  const [accessMethodName, setAccessMethodName] = useState(null)
  const [accessMethodFormOpen, setAccessMethodFormOpen] = useState(false)
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(null);
  const [accessLevelName, setAccessLevelName] = useState(null)
  const [accessLevelFormOpen, setAccessLevelFormOpen] = useState(false)

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountFormOpen, setAccountFormOpen] = useState(false)

  const handleAccessLevelEditOpen = (openVal, selectedID) => {
    if (selectedID === -1) {
      setAccessLevelName('')
    }
    else {
      const rowEdit = accessLevelRows.find((row) => row.id === selectedID);
      setAccessLevelName(rowEdit.accesslevel)
    }
    setAccessLevelFormOpen(openVal)
  }

  const handleAccessMethodEditOpen = (openVal, selectedID) => {
    if (selectedID === -1) {
      setAccessMethodName('')
    }
    else {
      const rowEdit = accessMethodRows.find((row) => row.id === selectedID);
      setAccessMethodName(rowEdit.accessmethod)
    }
    setAccessMethodFormOpen(openVal)
  }

  const handleAccountEditOpen = (openVal, selectedID) => {
    if (selectedID === -1) {
      
    }
    else {
      const rowEdit = accountRows.find((row) => row.id === selectedID);
      
    }
    setAccountFormOpen(openVal)
  }

  useEffect(() => {
    const loadPage = async () => {
      const validationStatus = await validateLoggedInUser();
      if (validationStatus === 'Unauthorized') {
        fetch(process.env.REACT_APP_API_URL+'/api/logout', {
          method: 'post',
          }).then(res => {
            window.location.href = '/'
          }).catch(function (error) {
            window.location.href = '/'
        });
      } else if (validationStatus === 'TrialExpired') {
        navigate('/subscriptionexpired');
      } else {
        fetch(process.env.REACT_APP_API_URL+'/api/systems/'+id)
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
                setName(response.data.name)
                setNotes(response.data.notes)
                setOwnerID(response.data.ownerpeopleid)
            }
          } else {
              raiseAlert('error', 'Error loading system');
          }
          setIsLoadingSystem(false)
        })
        .catch(function (error) {
          raiseAlert('error', 'Error loading system')
          setIsLoadingSystem(false)
        });

      fetch(process.env.REACT_APP_API_URL+'/api/systems/'+id+'/access_methods')
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
              setAccessMethodRows(response.data)
            }
          } else {
              raiseAlert('error', 'Error loading access methods');
          }
          setIsLoadingAccessMethods(false)
        })
        .catch(function (error) {
          raiseAlert('error', 'Error loading access methods')
          setIsLoadingAccessMethods(false)
        });

      fetch(process.env.REACT_APP_API_URL+'/api/systems/'+id+'/access_levels')
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
                setAccessLevelRows(response.data)
            }
          } else {
              raiseAlert('error', 'Error loading access levels');
          }
          
          setIsLoadingAccessLevels(false)
        })
        .catch(function (error) {
          raiseAlert('error', 'Error loading access levels')
          setIsLoadingAccessLevels(false)
        });

        fetch(process.env.REACT_APP_API_URL+'/api/systems/'+id+'/accounts')
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
                setAccountRows(response.data)
            }
          } else {
              raiseAlert('error', 'Error loading accounts');
          }
          
          setIsLoadingAccounts(false)
        })
        .catch(function (error) {
          raiseAlert('error', 'Error loading accounts')
          setIsLoadingAccounts(false)
        });
      
      fetch(process.env.REACT_APP_API_URL+'/api/people')
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
                const sorted_array = response.data.sort((a, b) => (a.fullname.toUpperCase() > b.fullname.toUpperCase()) ? 1 : -1)
                setPeopleFullNames(sorted_array.map(function(d, idx){
                  return ({label: d.fullname, value: d.id})
                }))
            }
          } else {
              raiseAlert('error', 'Error loading employees');
          }
          
          setIsLoadingPeople(false)
        }, [])
        .catch(function (error) {
          raiseAlert('error', 'Error loading employees')
          setIsLoadingPeople(false)
        });
      }
    };
    loadPage();
  }, [])

  function handleCompleteSaveAccessLevel() {
    setAccessLevelName(null)
    setAccessLevelFormOpen(false)
  }

  function handleCompleteSaveAccessMethod() {
    setAccessMethodName(null)
    setAccessMethodFormOpen(false)
  }

  if (isLoadingSystem || isLoadingPeople || isLoadingAccessMethods || isLoadingAccessLevels || isLoadingAccounts) {
    return (
      <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
        <Typography variant="h5" component="div" sx={{}}><StorageIcon sx={{verticalAlign: 'middle', marginRight: '20px'}} />System: {name}</Typography>
        <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Manage a specific system</Typography>
        <Box sx={{p: 1, paddingBottom: '0px' }}>
          <Grid container spacing={1}>
            <SystemsDetailForm 
              raiseAlert={raiseAlert}
              name={name}
              notes={notes}
              ownerID={ownerID} 
              setName={setName}
              setNotes={setNotes}
              setOwnerID={setOwnerID}
              peopleFullNames={peopleFullNames}
              id={id}
              />
            <Grid item sx={{textAlign: 'left'}}  xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" component="span" sx={{ flexGrow: 1, color: '#40434E' }}>
                <Link sx={{}} style={{paddingBottom: '14px', textDecoration: 'none', marginRight: '16px', paddingRight: '5px', fontSize: '14px', fontWeight: (currentPage === 'accesslevelmethod' ? '400' : '400'), borderBottom: (currentPage === 'accesslevelmethod' ? '1px solid #a93ac5' : 'none'), color: (currentPage === 'accesslevelmethod' ? '#a93ac5' : '#aaaaaa') }} href='#' onClick={(event) => {event.preventDefault(); setCurrentPage('accesslevelmethod')}}><><KeyIcon sx={{marginRight: '5px', verticalAlign: 'middle', fontSize: '20px', color: (currentPage === 'accesslevelmethod' ? '#a93ac5' : 'darkgrey')}} />Access Levels/Methods</></Link>
                <Link sx={{}} style={{paddingBottom: '14px', textDecoration: 'none', marginRight: '16px', paddingRight: '5px', fontSize: '14px', fontWeight: (currentPage === 'accounts' ? '400' : '400'), borderBottom: (currentPage === 'accounts' ? '1px solid #a93ac5' : 'none'), color: (currentPage === 'accounts' ? '#a93ac5' : '#aaaaaa') }} href='#' onClick={(event) => {event.preventDefault(); setCurrentPage('accounts')}}><AccountCircleIcon sx={{marginRight: '5px', verticalAlign: 'middle', fontSize: '20px', color: (currentPage === 'accounts' ? '#a93ac5' : 'darkgrey')}} />Accounts</Link>
              </Typography>
            </Grid>
          </Grid>
          </Box>
          <Divider sx={{marginTop: '10px', marginLeft: '-16px', width: 'calc(100% + 32px)'}} />
          <Box>
            <Grid sx={{p: 2}} spacing={1} container>
            {currentPage === 'accesslevelmethod' && 
            <>
              <Grid ref={accessLevelMethodRef} sx={{paddingBottom: '20px'}} item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Access Levels</Typography>
                <SystemsAccessLevelsGrid 
                  rows={accessLevelRows}
                  setRows={setAccessLevelRows}
                  raiseAlert={raiseAlert}
                  selectedRow={selectedAccessLevel}
                  setSelectedRow={setSelectedAccessLevel}
                  setEditOpen={handleAccessLevelEditOpen}
                  name={selectedAccessLevel}
                />
              </Grid>
              <Grid sx={{paddingBottom: '20px'}} item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Access Methods</Typography>
                <SystemsAccessMethodsGrid 
                  rows={accessMethodRows}
                  setRows={setAccessMethodRows}
                  raiseAlert={raiseAlert}
                  selectedRow={selectedAccessMethod}
                  setSelectedRow={setSelectedAccessMethod}
                  setEditOpen={handleAccessMethodEditOpen}
                  name={selectedAccessMethod}
                />

              </Grid>
            </>
            }
            {currentPage === 'accounts' && 
              <Grid ref={AccessAccountsRef}  sx={{paddingBottom: '20px'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Associate existing accounts with this system</Typography>
                <SystemsAccountsGrid  
                  rows={accountRows}
                  setRows={setAccountRows}
                  raiseAlert={raiseAlert}
                  selectedRow={selectedAccount}
                  setSelectedRow={setSelectedAccount}
                  setEditOpen={handleAccountEditOpen}
                  
                />
              </Grid>
            }
          </Grid>
        </Box>
        <AccessLevelForm 
          open={accessLevelFormOpen} 
          handleClose={handleCompleteSaveAccessLevel}
          handleCompleteSave={handleCompleteSaveAccessLevel}
          name={accessLevelName} 
          setName={setAccessLevelName} 
          raiseAlert={raiseAlert} 
          selectedId={selectedAccessLevel} 
          systemId={id} 
          setRows={setAccessLevelRows}
          rows={accessLevelRows}
          />
        <AccessMethodForm 
          open={accessMethodFormOpen} 
          handleClose={handleCompleteSaveAccessMethod}
          handleCompleteSave={handleCompleteSaveAccessMethod}
          name={accessMethodName} 
          setName={setAccessMethodName} 
          raiseAlert={raiseAlert} 
          selectedId={selectedAccessMethod} 
          systemId={id} 
          setRows={setAccessMethodRows}
          rows={accessMethodRows}
          />
      </Box>
    )
  }
}


export default SystemDetail;
