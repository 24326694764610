import { useEffect, useState } from 'react';
import {useOutletContext, Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { isAdmin } from '../components/Generic/ValidateLoggedInUser';


function SubscriptionExpired() {
  const raiseAlert = useOutletContext();
  const [alertRaised, setAlertRaised] = useState(false);

  useEffect(() => {
    if (!alertRaised) {
      raiseAlert('error', 'Subscription has expired.');
      setAlertRaised(true); // Ensure the alert is only raised once
    }
  }, [alertRaised, raiseAlert]);
  //To-Do - Make this page look better for both of the below.
  if (isAdmin()) {
    return (
      <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
        <Typography variant="h6" component="span" sx={{ flexGrow: 1, color: '#40434E' }}>
          Subscription Expired
        </Typography>  
        <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem',  marginBottom: '20px'}}>
          Click <Link style={{textStyle: 'italic', color:'black'}} to={'/settings/subscription'}>here</Link> to update your credit card information.
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
        <Typography variant="h6" component="span" sx={{ flexGrow: 1, color: '#40434E' }}>
          Subscription Expired
        </Typography>
        <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem',  marginBottom: '20px'}}>
          Please speak with your account administrator to update the credit card information.
        </Typography>
      </Box>
    );
  }
}

export default SubscriptionExpired;