import { useNavigate } from 'react-router-dom';
import { Grid, Box, Badge } from '@mui/material';
import { badgeClasses } from "@mui/material/Badge";

function HomeTile(props) {
    const navigate = useNavigate();

    return (
        <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box onClick={() => {if (props.to !== '') navigate(props.to)}} sx={{ cursor: (props.to !== '' ? 'pointer' : 'default'), backgroundColor: '#f0f0f0', position: 'relative', padding: {xs: '8px 20px 10px 40px', sm: '8px 20px 10px 40px', md: '40px 40px 10px 40px', lg: '40px 40px 10px 40px', xl: '40px 40px 10px 40px'}, minHeight: {xs: '100px', sm: '100px', md: '200px', lg: '200px', xl: '200px'}, border: '0px solid black'}}>
                    <div style={{position: 'absolute', top: '40px', left: '0px', width: '50px', height: '50px'}}>
                        <Badge sx={{
                            [`& .${badgeClasses.standard}`]: {height: '50px', minWidth: '50px', borderRadius: '25px'}}}badgeContent={props.step_number} color="primary"></Badge>
                    </div>
                    <Box sx={{textAlign: 'left', fontWeight: 'bold', fontSize: '20px'}}>
                    {props.title}
                    </Box>
                    <Box sx={{marginTop: '10px', textAlign: 'left', fontSize: '12px'}}>
                        {props.content}
                    </Box>
                </Box>
            </Grid>
        </>
    )
}

export default HomeTile;
