import { useState, useEffect } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PeopleGrid from '../components/People/PeopleGrid';
import PeopleForm from '../components/People/PeopleForm';
import GenericConfirm from '../components/Generic/GenericConfirm';
import { validateLoggedInUser } from '../components/Generic/ValidateLoggedInUser';

function People() {
  const navigate = useNavigate();
  const raiseAlert = useOutletContext();
  const [editOpen, setEditOpen] = useState(false);
  const [editConfirmationOpen, setEditConfirmationOpen] = useState(false);
  const [selectedRow, setselectedRow] = useState(-1);
  const [isLoading, setLoading] = useState(true)
  const [rows, setRows] = useState([]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  
  const handleEditOpen = (openVal, selectedID) => {
    if (selectedID === -1) {
      setFirstName('')
      setLastName('')
      setEmail('')
    }
    else {
      const rowEdit = rows.find((row) => row.id === selectedID);
      setFirstName(rowEdit.family_name)
      setLastName(rowEdit.given_name)
      setEmail(rowEdit.email)
    }
    setEditOpen(openVal)
  }

  const handleFormClose = () => {
    setEditConfirmationOpen(true)
  };

  const handleCompleteSave = (id) => {
    setEditConfirmationOpen(false)
    setEditOpen(false)
    navigate("/people/"+id);
  };

  const handleFormConfirmationCloseCancel = (event) => {
    setEditConfirmationOpen(false)
  };

  const handleFormConfirmationCloseOK = (event) => {
    setEditConfirmationOpen(false)
    setEditOpen(false)
  };

  useEffect(() => {
    const loadPage = async () => {
      const validationStatus = await validateLoggedInUser();
      if (validationStatus === 'Unauthorized') {
        fetch(process.env.REACT_APP_API_URL+'/api/logout', {
          method: 'post',
          }).then(res => {
            window.location.href = '/'
          }).catch(function (error) {
            window.location.href = '/'
        });
      } else if (validationStatus === 'TrialExpired') {
        navigate('/subscriptionexpired');
      } else {
        fetch(process.env.REACT_APP_API_URL+'/api/people')
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
                setRows(response.data)
            }
          } else {
              raiseAlert('error', 'Error loading people');
          }
          setLoading(false)
        })
        .catch(function (error) {
          raiseAlert('error', 'Error loading people')
          setLoading(false)
        });
      }
    };
    loadPage();
  }, [])

    return (
      <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
        <Typography variant="h5" component="div" sx={{}}><EmojiPeopleIcon sx={{verticalAlign: 'middle', marginRight: '20px'}}/>Employees</Typography>
        <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Manage your full list of employees here</Typography>
        <Box sx={{p: 1, }}>
        <PeopleForm 
          open={editOpen} 
          handleClose={handleFormClose} 
          handleCompleteSave={handleCompleteSave}
          selectedRow={selectedRow} 
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          raiseAlert={raiseAlert}
          rows={rows}
          setRows={setRows}
          />
        <GenericConfirm title='Cancel changes?' text='Are you sure you want to cancel your changes?' handleCloseOK={handleFormConfirmationCloseOK} handleCloseCancel={handleFormConfirmationCloseCancel} open={editConfirmationOpen} />
        <PeopleGrid 
          rows={rows}
          setRows={setRows}
          raiseAlert={raiseAlert}
          editOpen={editOpen}
          setEditOpen={handleEditOpen}
          setselectedRow={setselectedRow}
          isLoading={isLoading}
          />
        </Box>
      </Box>
    )
}

export default People;
