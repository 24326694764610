import { useState } from 'react'
import { Box, Modal, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ForgottenPassword(props) {
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
      };
    
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState(null);
    
    function validateForm(field) {
        var anyError = false;
        if (field === 'username') {
            if (username.length <= 1) {
                setUsernameError('Enter an email address')
                anyError = true;
            }
            else
                setUsernameError(null)
        }
        
        return anyError;
    }

    function clickForgottenPassword() {
        
        let usernameErrorCheck = validateForm('username');

        if (usernameErrorCheck)
            return false;
        else {
            const headers = {
                'Content-Type': 'application/json'
            }

            fetch(process.env.REACT_APP_API_URL+'/api/reset_password?email='+encodeURIComponent(username), {
                method: 'get',
                headers: headers
                }).then((response) => {
                    if(!response.ok)
                        return false
                    else
                        return response.json()
                }).then((response) => {
                    if(response) {
                        if(response.error) {
                            props.raiseAlert('error', response.error);
                        } else {
                            setUsername('')
                            props.handleClose()
                            props.raiseAlert('success', 'Reset password successfully requested');
                        }
                    } else {
                        props.raiseAlert('error', 'Error resetting password');
                    }
                })
                .catch(function (error) {
                    props.raiseAlert('error', 'Error during password reset')
                });
        }
            
    }

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
            >
                <Box component="form" sx={style} >
                    <div style={{float: 'right'}}><Button sx={{minWidth: '30px'}} onClick={props.handleClose}><CloseIcon/></Button></div>
                    <Box sx={{p: 2}}>
                        <h2>Forgotten Password</h2>
                        <TextField value={username} onChange={(event) => {setUsername(event.target.value)}} onBlur={(event) => {validateForm('username')}} margin="normal" required fullWidth id="outlined-basic" label="Email" variant="outlined" error={!!usernameError} helperText={usernameError} />
                        <Box sx={{marginTop: '15px', display: 'flex',}}>
                            <Button onClick={(event) => {clickForgottenPassword()}} disabled={!!usernameError} sx={{ padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >Forgot Password</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ForgottenPassword;
