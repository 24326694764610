import { useState } from 'react'
import { Box, Modal, TextField, MenuItem, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function UsersForm(props) {
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
      };
    
    const [givenNameError, setGivenNameError] = useState(null);
    const [familyNameError, setFamilyNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [blockedError, setBlockedError] = useState(null);
    const [isAdminError, setIsAdminError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    function validateForm(field) {
        var anyError = false;
        if (field === 'given_name') {
            if (props.givenName.length < 1) {
                setGivenNameError('First name must exist')
                anyError = true;
            }
            else {
                setGivenNameError(null)
            }
        }
        if (field === 'family_name') {
            if (props.familyName.length < 1) {
                setFamilyNameError('Last name must exist')
                anyError = true;
            }
            else {
                setFamilyNameError(null)
            }
        }
        if (field === 'email') {
            var validEmailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (props.email.match(validEmailRegex)) {
                setEmailError(null)
            } else {
                setEmailError('Invalid email address')
                anyError = true;
            }
        }
        if (field === 'blocked') {
            if (String(props.blocked) !== 'true' && String(props.blocked) !== 'false') {
                setBlockedError('Invalid blocked status')
                anyError = true;
            }
            else {
                setBlockedError(null)
            }
        }
        if (field === 'is_admin') {
            if (String(props.isAdmin) !== 'true' && String(props.isAdmin) !== 'false') {
                setIsAdminError('Invalid user status')
                anyError = true;
            }
            else {
                setIsAdminError(null)
            }
        }

        return anyError;
    }

    function clickResetPassword() {
        const headers = {
            'Content-Type': 'application/json'
        }
        
        fetch(process.env.REACT_APP_API_URL+'/api/reset_password_by_id?id='+encodeURIComponent(props.selectedRow), {
            method: 'get',
            headers: headers
            }).then((response) => {
                if(!response.ok)
                    return false
                else
                    return response.json()
            }).then((response) => {
                if(response) {
                    if(response.error) {
                        props.raiseAlert('error', response.error);
                    } else {
                        props.raiseAlert('success', 'Reset password successfully requested');
                    }
                } else {
                    props.raiseAlert('error', 'Error during password reset');
                }
            }).catch(function (error) {
                props.raiseAlert('error', 'Error during password reset')
            });
    }

    function clickSave() {
        setIsLoading(true)
        let familyNameErrorCheck = validateForm('family_name');
        let givenNameErrorCheck = validateForm('given_name');
        let emailErrorCheck = validateForm('email');
        let blockedErrorCheck = validateForm('blocked');
        
        if (givenNameErrorCheck || familyNameErrorCheck || emailErrorCheck || blockedErrorCheck) {
            props.raiseAlert('error', 'Error whilst saving record')
            return false;
        }
        else {
            const post_data = {
                'id': props.selectedRow,
                'family_name': props.familyName,
                'given_name': props.givenName,
                'email': props.email,
                'blocked': props.blocked,
                'email_verified': props.emailVerified,
                'is_admin': props.isAdmin
            }
            
            const post_headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

            fetch(process.env.REACT_APP_API_URL+'/api/users', {
                method: 'put',
                body: new URLSearchParams(post_data),
                headers: post_headers
                }).then((response) => {
                    if(!response.ok)
                        return false
                    else
                        return response.json()
                }).then((response) => {
                    if(response) {
                        if(response.error) {
                            props.raiseAlert('error', response.error);
                        } else {
                            const newRow = response.data
                            if (props.selectedRow === -1) {
                                props.setRows((oldRows) => [...oldRows, newRow]);
                                props.raiseAlert('success', 'Created new user')
                            }
                            else {
                                props.setRows(props.rows.map((row) => (row.id === props.selectedRow ? newRow : row)));
                                props.raiseAlert('success', 'Updated user')
                            }
                            props.handleCompleteSave()
                        }
                    } else {
                        props.raiseAlert('error', 'Error during save');
                    }

                    setIsLoading(false)
                })
                .catch(function (error) {
                    props.raiseAlert('error', 'Error during save')
                    setIsLoading(false)
                });
        }
    }

    if (isLoading) {
        return (
            <>
                <Modal
                    open={props.open}
                    onClose={props.handleClose}
                >
                    <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </Modal>
            </>
        )
    }
    else {
        return (
            <>
                <Modal
                    open={props.open}
                    onClose={props.handleClose}
                >
                    <Box component="form" sx={style} >
                        <div style={{float: 'right'}}><Button sx={{minWidth: '30px'}} onClick={props.handleClose}><CloseIcon/></Button></div>
                        <Box sx={{p: 2}}>
                            <h2>{(props.selectedRow === -1 ? 'New' : 'Edit')} User</h2>
                            <TextField value={props.givenName} onChange={(event) => {props.setGivenName(event.target.value)}} onBlur={(event) => {validateForm('given_name')}} margin="normal" required fullWidth id="outlined-basic" label="First Name" variant="outlined" error={!!givenNameError} helperText={givenNameError} />
                            <TextField value={props.familyName} onChange={(event) => {props.setFamilyName(event.target.value)}} onBlur={(event) => {validateForm('family_name')}} margin="normal" required fullWidth id="outlined-basic" label="Last Name" variant="outlined" error={!!familyNameError} helperText={familyNameError}  />
                            <TextField value={props.email} onChange={(event) => {props.setEmail(event.target.value)}} onBlur={(event) => {validateForm('email')}} margin="normal" required fullWidth id="outlined-basic" label="Email" variant="outlined" error={!!emailError} helperText={emailError}  />
                            <TextField select label='Status' margin="normal" sx={{marginTop: '10px'}} error={!!blockedError} helperText={blockedError} style={{width: '100%', marginBottom: '10px'}} id="select" value={props.blocked} onChange={(event) => {props.setBlocked(event.target.value)}} >
                                <MenuItem value='true'>Inactive</MenuItem>
                                <MenuItem value='false'>Active</MenuItem>
                            </TextField>
                            <TextField select label='Type' margin="normal" sx={{marginTop: '10px'}} error={!!isAdminError} helperText={isAdminError} style={{width: '100%', marginBottom: '20px'}} id="select" value={props.isAdmin} onChange={(event) => {props.setIsAdmin(event.target.value)}} >
                                <MenuItem value='false'>Standard User</MenuItem>
                                <MenuItem value='true'>Admin</MenuItem>
                            </TextField>
                            <Box sx={{marginTop: '15px', display: 'flex',}}>
                                <Box><Button onClick={(event) => {clickSave()}} disabled={!!givenNameError || !!familyNameError} sx={{ padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >Save</Button></Box>
                                <Box sx={{flexGrow: 1}}></Box>
                                <Box><Button onClick={(event) => {clickResetPassword()}} sx={{ padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >Reset User Password</Button></Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </>
        )
    }
}

export default UsersForm;
