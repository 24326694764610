import { Box } from '@mui/material';
import {
  DataGrid,
  GridToolbar
} from '@mui/x-data-grid';
import { CircularProgress } from '@mui/material';

export default function AuditLogGrid(props) {
  const columns = [
    {
      field: 'entityid',
      headerName: 'Entity ID',
      width: 100,
      editable: false,
    },
    {
      field: 'entity',
      headerName: 'Object Type',
      width: 180,
      editable: false,
    },
    {
      field: 'created',
      headerName: 'Log Date',
      width: 300,
      editable: false,
    },
    {
      field: 'logaction',
      headerName: 'Action',
      width: 100,
      editable: false,
    },
    {
      field: 'useremail',
      headerName: 'User Email',
      width: 300,
      editable: false,
    },
    {
      field: 'newdata',
      headerName: 'Log',
      width: 900,
      editable: false
    },
  ];

  if (props.isLoading) {
    return (
      <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={props.rows}
        columns={columns}
        rowHeight={25}
        slots={{ toolbar: GridToolbar }}
        disableDensitySelector
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true }
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 25 } },

        }}
      />
    </Box>
  );
}