import { useState } from 'react'
import { Box, Modal, TextField, MenuItem, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function IdentityProviderForm(props) {
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
      };
    
    const [nameError, setNameError] = useState(null);
    const [notesError, setNotesError] = useState(null);
    const [ownerIDError, setOwnerIDError] = useState(null);
    
    function validateForm(field) {
        var anyError = false;
        if (field === 'name') {
            if (props.name.length <= 1) {
                setNameError('System name must be at least two characters')
                anyError = true;
            }
            else
                setNameError(null)
        }
        if (field === 'notes') {
            setNotesError(null)
        }
        if (field === 'ownerID') {
            if (props.ownerID.length <= 1) {
                setOwnerIDError('Owner can not be empty')
                anyError = true;
            }
            else
                setOwnerIDError(null)
        }

        return anyError;
    }

    function clickSave() {
        
        let nameErrorCheck = validateForm('name');
        let notesErrorCheck = validateForm('notes');
        let ownerErrorCheck = validateForm('ownerID');
        
        if (nameErrorCheck || notesErrorCheck || ownerErrorCheck) {
            props.raiseAlert('error', 'Error whilst saving record')
            return false;
        }
        else {
            const post_data = {
                'id': props.selectedSystem,
                'name': props.name,
                'notes': props.notes,
                'ownerpeopleid': props.ownerID
            }
            let formData = new FormData();
            formData.append('id', props.selectedSystem);
            formData.append('name', props.name);
            formData.append('notes', props.notes);
            formData.append('ownerpeopleid', props.ownerID);
            const post_headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

            fetch(process.env.REACT_APP_API_URL+'/api/systems', {
                method: 'put',
                body: new URLSearchParams(post_data),
                headers: post_headers
                }).then((response) => {
                    if(!response.ok)
                        return false
                    else
                        return response.json()
                }).then((response) => {
                    if(response) {
                        if(response.error) {
                            props.raiseAlert('error', response.error);
                        } else {
                            const newRow = response.data
                            props.setRows((oldRows) => [...oldRows, newRow]);
                            props.raiseAlert('success', 'Created new system')
                            props.handleCompleteSave(response.data.id)
                        }
                    } else {
                        props.raiseAlert('error', 'Error creating new system');
                    }
                })
                .catch(function (error) {
                    props.raiseAlert('error', 'Error during save')
                });
        }
    }

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
            >
                <Box component="form" sx={style} >
                    <div style={{float: 'right'}}><Button sx={{minWidth: '30px'}} onClick={props.handleClose}><CloseIcon/></Button></div>
                    <Box sx={{p: 2}}>
                        <h2>{(props.selectedSystem === -1 ? 'New' : 'Edit')} System</h2>
                        <TextField value={props.name} onChange={(event) => {props.setName(event.target.value)}} onBlur={(event) => {validateForm('name')}} margin="normal" required fullWidth id="outlined-basic" label="System Name" variant="outlined" error={!!nameError} helperText={nameError} />
                        <TextField value={props.notes} onChange={(event) => {props.setNotes(event.target.value)}} onBlur={(event) => {validateForm('notes')}} margin="normal" fullWidth id="outlined-basic" label="Notes" variant="outlined" error={!!notesError} helperText={notesError}  />
                        <TextField select label='Owner' margin="normal" required error={!!ownerIDError} helperText={ownerIDError} style={{width: '100%', marginBottom: '20px'}} id="select" value={props.ownerID} onChange={(event) => {props.setOwnerID(event.target.value)}} >
                            {Object.keys(props.peopleFullNames).map((keyName, i) => (
                                <MenuItem value={props.peopleFullNames[i].value}>{props.peopleFullNames[i].label}</MenuItem>
                            ))}
                        </TextField>
                        <Button onClick={(event) => {clickSave()}} disabled={!!nameError || !!notesError} sx={{ padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >Save</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default IdentityProviderForm;
