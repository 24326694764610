import { Grid, Box } from '@mui/material';
import { motion } from "framer-motion";

function PricingColumn(props) {

    function FadeInWhenVisible({ children }) {
        if (props.alreadyAnimated) {
            return <>{children}</>;
        } else {
            return (
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: props.duration }}
                variants={{
                visible: { opacity: 1, marginTop: 0 },
                hidden: { opacity: 0, marginTop: 100 }
                }}
                onAnimationComplete={() => {if (props.final_transition) props.setAlreadyAnimated(true)}}
            >
                {children}
            </motion.div>
            );
        }
      }
    
    return (
        <>
            <Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
            <FadeInWhenVisible>
                <Box sx={{borderRadius: '15px', backgroundColor: 'white', height: props.height, borderWidth: props.borderWidth, border: props.border}}>
                <header style={{paddingTop: '50px', fontWeight: 'bold', fontSize: '20px'}}>{props.header}</header>
                <p style={{marginBottom: '10px', fontWeight: 'bold', fontSize: '40px', color: props.color}}>
                    {isNaN(props.price) && 'Call Us'}
                    {!isNaN(props.price) &&
                        <><span style={{fontSize: '15px'}}>$</span>{ props.price }<span style={{fontSize: '15px'}}>USD</span></>
                    }
                </p>
                {isNaN(props.price) && 
                    <p style={{marginBottom: '40px', marginTop: 0}}>&nbsp;</p>
                }
                {!isNaN(props.price) && 
                    <p style={{marginBottom: '40px', marginTop: 0}}>per {props.pricingTerm.substring(0, props.pricingTerm.length-2)}</p>
                }
                <p>{props.description}</p>
                
                {isNaN(props.price) && 
                    <props.StyledButton id={props.buttonID} onClick={props.handlePricingSignupButtonClick} sx={{ padding: '10px 20px 10px 20px', fontWeight: 'bold', backgroundColor: '#40434E', color: 'white' }}>
                        Contact Us
                    </props.StyledButton>
                }
                {!isNaN(props.price) &&
                    <props.StyledButton id={props.buttonID} onClick={props.handlePricingSignupButtonClick} sx={{ padding: '10px 20px 10px 20px', fontWeight: 'bold', backgroundColor: '#40434E', color: 'white' }}>
                        14 Day Free Trial
                    </props.StyledButton>
                }
                
                </Box>
                </FadeInWhenVisible>
            </Grid>
            
        </>
    )
}

export default PricingColumn;
