import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { validateLoggedInUser } from '../Generic/ValidateLoggedInUser';
import HomeTile from './HomeTile';

function HomeAuthed() {
  const navigate = useNavigate();

  useEffect(() => {
    const loadPage = async () => {
      const validationStatus = await validateLoggedInUser();
      // No Unauthorized check here as it shouldn't be required and we don't want to risk an infinate redirect loop if somehow it does go wrong.
      if (validationStatus === 'TrialExpired') {
        navigate('/subscriptionexpired');
      } else {
        //Main page logic goes here
      }
    };
    loadPage();
  }, [])
  
  return (
    <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
      <Typography variant="h6" component="span" sx={{ flexGrow: 1, color: '#40434E' }}>
        Welcome to LoginRegister!
      </Typography>
      <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem',  marginBottom: '20px'}}>
        LoginRegister is an application that catalogs your enterprise systems, the accounts on those systems and who has access to them. Using LoginRegister not only supports compliaance, but also simplifies the leaving process, audits and change management.
      </Typography>
      <Typography variant="h6" component="div" sx={{ textAlign: 'center', color: '#40434E', marginBottom: '5px' }}>
        How it works
      </Typography>
      <Typography component="div" sx={{padding: '0px 50px 0px 50px', textAlign: 'center', fontSize: '12px',  marginBottom: '20px', position: 'relative', zIndex: '999'}}>
        Six simple steps to get up and running, to help out we've added your first System (this app!), first employee, and first account ({jwtDecode(Cookies.get('auth0IDtoken')).email}). Take a look around.
      </Typography>
      <Box sx={{paddingLeft: '50px', paddingRight: '50px'}}>
      <Grid container spacing={4}>
        <HomeTile to={''} completed={true} step_number={'1'} title={'Register'} content={"Register for LoginRegister. Tick this off the list, you're signed up."} />
        <HomeTile to={'systems'} completed={true} step_number={'2'} title={'Create Systems'} content={"Set up your systems. Active Directory, CRM, ERP, Database and don't forget those under-the-radar systems that no one knows about apart from you."} />
        <HomeTile to={'people'} completed={false} step_number={'3'} title={'Create Employees'} content={"Create or import the set of employees you want to manage. We call people 'Employees', but you can just as easily use LoginRegister to manage external third-parties and casual workers."} />
        <HomeTile to={''} completed={false} step_number={'4'} title={'Create Accounts'} content={"Add the accounts you want to link to each system. These might be individual accounts or system accounts."} />
        <HomeTile to={''} completed={false} step_number={'5'} title={'Link Employees, Accounts and Systems'} content={"Set which employees have access to which accounts, and which accounts have access to which systems."} />
        <HomeTile to={''} completed={false} step_number={'6'} title={'Marvel'}  content={<>You're now well on your way to managing the risks around user accounts. Why not try generating a <Link style={{textStyle: 'italic', color:'black'}} to={'reports'}>report</Link>, or check out the <Link style={{textStyle: 'italic', color:'black'}} to={'networkchart'}>network chart</Link>.</>} />
      </Grid>
      </Box>
    </Box>
  )
}

export default HomeAuthed;
