import { useState } from 'react'
import { useNavigate} from 'react-router-dom';
import { CircularProgress, TextField, Button, MenuItem, Grid, Tooltip  } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function SystemsDetailForm(props) {
  const [nameError, setNameError] = useState(null);
  const [notesError, setNotesError] = useState(null);
  const [ownerIDError, setOwnerIDError] = useState(null);
  const [isSavingSystem, setIsSavingSystem] = useState(false);

  const navigate = useNavigate();
  
  function validateForm(field) {
    var anyError = false;
    if (field === 'name') {
      if (props.name.length <= 1) {
        setNameError('System name must be at least two characters')
        anyError = true;
      }
      else {
        setNameError(null)
      }
    }

    if (field === 'notes') {
      setNotesError(null)
    }
    if (field === 'ownerID') {
      if (props.ownerID.length <= 1) {
        setOwnerIDError('Owner can not be empty')
        anyError = true;
      }
      else {
        setOwnerIDError(null)
      }
    }

    return anyError;
  }

  function clickBack() {
    navigate('/systems')
  }

  function clickSave() {
    setIsSavingSystem(true);
    validateForm('name');
    validateForm('notes');
    validateForm('ownerID');
    
    if (nameError || notesError || ownerIDError) {
      props.raiseAlert('error', 'Error whilst saving record')
      setIsSavingSystem(false);
      return false;
    }
    else {
      const post_data = {
        'id': props.id,
        'name': props.name,
        'notes': props.notes,
        'ownerpeopleid': props.ownerID
      }
        
      const post_headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }

      fetch(process.env.REACT_APP_API_URL+'/api/systems', {
        method: 'put',
        body: new URLSearchParams(post_data),
        headers: post_headers
      }).then((response) => {
        if(!response.ok)
            return false
        else
            return response.json()
      }).then((response) => {
        if(response) {
          if(response.error) {
              props.raiseAlert('error', response.error);
          } else {
            props.raiseAlert('success', 'Updated system')
          }
        } else {
            props.raiseAlert('error', 'Error during save');
        }
        setIsSavingSystem(false);
      }).catch(function (error) {
        props.raiseAlert('error', 'Error during save')
        setIsSavingSystem(false);
      });
    }
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Tooltip title='Back'>
          <Button onClick={(event) => {clickBack()}} sx={{minWidth: '32px'}} size='small'>
            <ArrowBackIcon sx={{color: '#40434E'}}/>
          </Button>
        </Tooltip>
        <Tooltip title='Save'>
          <Button disabled={isSavingSystem} onClick={(event) => {clickSave()}} sx={{minWidth: '32px'}} size='small'>
            {isSavingSystem ? <CircularProgress size={'16px'} /> : <SaveIcon />}
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <TextField margin='normal' size='small' value={props.name} onChange={(event) => {props.setName(event.target.value)}} onBlur={(event) => {validateForm('name')}} required fullWidth id="outlined-basic" label="System Name" variant="outlined" error={!!nameError} helperText={nameError} />
        <TextField margin='normal' size='small' error={!!ownerIDError} helperText={ownerIDError} style={{width: '100%', marginBottom: '20px'}} select value={props.ownerID} onChange={(event) => {props.setOwnerID(event.target.value)}} label="Owner" >
          {Object.keys(props.peopleFullNames).map((keyName, i) => (
            <MenuItem value={props.peopleFullNames[i].value}>{props.peopleFullNames[i].label}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <TextField rows={4} multiline size='small' value={props.notes} onChange={(event) => {props.setNotes(event.target.value)}} onBlur={(event) => {validateForm('notes')}} margin="normal" fullWidth id="outlined-basic" label="Notes" variant="outlined" error={!!notesError} helperText={notesError}  />
      </Grid>
    </>
  )
}