'use client'
import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Button, Typography, Grid, CircularProgress, Box, TextField } from '@mui/material';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

function ManageSubscription(props) {
  const raiseAlert = useOutletContext();
  const [isLoading, setLoading] = useState(true)
  const [ownerEmail, setOwnerEmail] = useState('');
  const [currentSubscription, setCurrentSubscription] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [addressState, setAddressState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  
  
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'/api/subscription')
      .then((response) => {
        if(!response.ok)
            return false
        else
            return response.json()
      })
      .then((response) => {
        if(response) {
          if(response.error) {
              props.raiseAlert('error', response.error);
          } else {
            if (response.data.customer.address) {
              setAddressLine1(response.data.customer.address.line1 || ' ')
              setAddressLine2(response.data.customer.address.line2 || ' ')
              setCity(response.data.customer.address.city || ' ')
              setCountry(response.data.customer.address.country || ' ')
              setAddressState(response.data.customer.address.state || ' ')
              setPostalCode(response.data.customer.address.postal_code || ' ')
            } else {
              setAddressLine1('')
              setAddressLine2('')
              setCity('')
              setCountry('')
              setAddressState('')
              setPostalCode('')
            }
            setOwnerEmail(response.data.customer.email)
            setCurrentSubscription(response.data.subscription.items.data[0].price.nickname)
            if (response.data.subscription.status === 'incomplete') 
              setSubscriptionStatus('Payment failed')
            if (response.data.subscription.status === 'incomplete_expired') 
              setSubscriptionStatus('Payment failed')
            if (response.data.subscription.status === 'trialing') {
              var utcSeconds = response.data.subscription.trial_end;
              var trial_end_date = new Date(0);
              trial_end_date.setUTCSeconds(utcSeconds);
              const current_date = new Date()
              const days_remaining = Math.round((trial_end_date - current_date)/1000/60/60/24)
              if (days_remaining === 1)
                setSubscriptionStatus('In trial period - '+days_remaining+' day remaining')
              else
                setSubscriptionStatus('In trial period - '+days_remaining+' days remaining')
            }
            if (response.data.subscription.status === 'active') 
              setSubscriptionStatus('Active')
            if (response.data.subscription.status === 'past_due') 
              setSubscriptionStatus('Payment failed')
            if (response.data.subscription.status === 'canceled') 
              setSubscriptionStatus('Canceled')
            if (response.data.subscription.status === 'unpaid') 
              setSubscriptionStatus('Payment failed')
          }
        } else {
            props.raiseAlert('error', 'Error loading subscription information');
        }
        setLoading(false)
      })
      .catch(function (error) {
        raiseAlert('error', 'Error loading subscription information')
        setLoading(false)
    });
  }, [])

  return (
    <>
        <div style={{display: props.display}}>
        <Typography sx={{}} variant='h6'>Manage Subscription</Typography>
        <Typography component="div" sx={{marginBottom: '20px', fontWeight: '200', fontSize: '0.8rem'}}>To modify your details, <a href={'https://billing.stripe.com/p/login/9AQaFE9gWfls4fe4gg?prefilled_email=' + encodeURIComponent(jwtDecode(Cookies.get('auth0IDtoken')).email)}>open the billing portal</a></Typography>
        {isLoading && 
        (<Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
          <CircularProgress />
        </Box>)
        }
        {!isLoading && 
          <Grid container spacing={2}>
            <Grid item sx={{textAlign: 'right'}} xs={12} sm={12} md={8} lg={8} xl={8}>
              <TextField
                type="text"
                label="Owner email address"
                variant="outlined"
                value={ownerEmail}
                fullWidth
                disabled
                margin='dense'
                size='small'
                />
            </Grid>
            <Grid item sx={{textAlign: 'right', display: {xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'}}} xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
            <Grid item sx={{textAlign: 'right'}} xs={12} sm={12} md={8} lg={8} xl={8}>
              <TextField
                type="text"
                label="Current Subscription"
                variant="outlined"
                value={currentSubscription}
                fullWidth
                disabled
                margin='dense'
                size='small'
                />
            </Grid>
            <Grid sx={{padding: {xs: '0px 0px 0px 30px !important', sm: '0px 0px 0px 30px !important', md: '30px 0px 0px 5px !important', lg: '30px 0px 0px 5px !important', xl: '30px 0px 0px 5px !important'}}} item xs={12} sm={12} md={4} lg={4} xl={4}>
              <a href='#'>Change Subscription</a>
            </Grid>
            <Grid item sx={{textAlign: 'right'}} xs={12} sm={12} md={8} lg={8} xl={8}>
              <TextField
                type="text"
                label="Subscription Status"
                variant="outlined"
                value={subscriptionStatus}
                fullWidth
                disabled
                margin='dense'
                size='small'
                />
            </Grid>
            <Grid item sx={{textAlign: 'right', display: {xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'}}} xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
            <Grid item sx={{}} xs={12} sm={12} md={8} lg={8} xl={8}>
              <TextField
                type="text"
                label="Billing Address Line 1"
                variant="outlined"
                value={addressLine1}
                fullWidth
                disabled
                margin='dense'
                size='small'
                />
              <TextField
                type="text"
                label="Billing Address Line 2"
                variant="outlined"
                value={addressLine2}
                fullWidth
                disabled
                margin='dense'
                size='small'
                />
              <TextField
                type="text"
                label="Billing Address City"
                variant="outlined"
                value={city}
                fullWidth
                disabled
                margin='dense'
                size='small'
                />
              <TextField
                type="text"
                label="Billing Address Postal"
                variant="outlined"
                value={postalCode}
                fullWidth
                disabled
                margin='dense'
                size='small'
                />
              <TextField
                type="text"
                label="Billing Address State"
                variant="outlined"
                value={addressState}
                fullWidth
                disabled
                margin='dense'
                size='small'
                />
              <TextField
                type="text"
                label="Billing Address Country"
                variant="outlined"
                value={country}
                fullWidth
                disabled
                margin='dense'
                size='small'
                
                />
            </Grid>
            <Grid item sx={{textAlign: {xs: 'center', sm: 'center', md: 'left', lg: 'left', xl: 'left'}}} xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button href={'https://billing.stripe.com/p/login/9AQaFE9gWfls4fe4gg?prefilled_email=' + encodeURIComponent(jwtDecode(Cookies.get('auth0IDtoken')).email)}  target='_blank' sx={{ padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >Open Billing Portal</Button>
            </Grid>
          </Grid>
        }
        </div> 
    </>
  );
}

export default ManageSubscription;
