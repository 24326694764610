import { useState } from 'react'
import { Box, Modal, TextField, Button, CircularProgress  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function PeopleForm(props) {
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
      };
    
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    function validateForm(field) {
        var anyError = false;
        if (field === 'firstName') {
            if (props.firstName.length < 1) {
                setFirstNameError('First name must exist')
                anyError = true;
            }
            else {
                setFirstNameError(null)
            }
        }
        if (field === 'lastName') {
            if (props.lastName.length < 1) {
                setLastNameError('Last name must exist')
                anyError = true;
            }
            else {
                setLastNameError(null)
            }
        }
        if (field === 'email') {
            var validEmailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (props.email.match(validEmailRegex)) {
                setEmailError(null)
            } else {
                setEmailError('Invalid email address')
                anyError = true;
            }
        }
        
        return anyError;
    }

    function clickSave() {
        setIsLoading(true)
        let firstNameErrorCheck = validateForm('firstName');
        let lastNameErrorCheck = validateForm('lastName');
        let emailErrorCheck = validateForm('email');
        
        if (firstNameErrorCheck || lastNameErrorCheck || emailErrorCheck) {
            props.raiseAlert('error', 'Error whilst saving record')
            return false;
        }
        else {
            const post_data = {
                'id': props.selectedRow,
                'firstname': props.firstName,
                'lastname': props.lastName,
                'email': props.email
            }
            
            const post_headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

            fetch(process.env.REACT_APP_API_URL+'/api/people', {
                method: 'put',
                body: new URLSearchParams(post_data),
                headers: post_headers
                }).then((response) => {
                    if(!response.ok)
                        return false
                    else
                        return response.json()
                }).then((response) => {
                    if(response) {
                        if(response.error) {
                            props.raiseAlert('error', response.error);
                        } else {
                            const newRow = response.data
                            if (props.selectedRow === -1) {
                                props.setRows((oldRows) => [...oldRows, newRow]);
                                props.raiseAlert('success', 'Created new employee')
                                
                            }
                            else {
                                props.setRows(props.rows.map((row) => (row.id === props.selectedRow ? newRow : row)));
                                props.raiseAlert('success', 'Updated employee')
                            }
                            props.handleCompleteSave(response.data.id)
                        }
                    } else {
                        props.raiseAlert('error', 'Error saving record');
                    }
                    setIsLoading(false)
                })
                .catch(function (error) {
                    props.raiseAlert('error', 'Error during save')
                    setIsLoading(false)
                });
        }
    }

    if (isLoading) {
        return (
            <>
                <Modal
                    open={props.open}
                    onClose={props.handleClose}
                >
                    <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </Modal>
            </>
        )
    }
    else {
        return (
            <>
                <Modal
                    open={props.open}
                    onClose={props.handleClose}
                >
                    <Box component="form" sx={style} >
                        <div style={{float: 'right'}}><Button sx={{minWidth: '30px'}} onClick={props.handleClose}><CloseIcon/></Button></div>
                        <Box sx={{p: 2}}>
                            <h2>{(props.selectedRow === -1 ? 'New' : 'Edit')} Employee</h2>
                            <TextField value={props.firstName} onChange={(event) => {props.setFirstName(event.target.value)}} onBlur={(event) => {validateForm('firstName')}} margin="normal" required fullWidth id="outlined-basic" label="First Name" variant="outlined" error={!!firstNameError} helperText={firstNameError} />
                            <TextField value={props.lastName} onChange={(event) => {props.setLastName(event.target.value)}} onBlur={(event) => {validateForm('lastName')}} margin="normal" required fullWidth id="outlined-basic" label="Last Name" variant="outlined" error={!!lastNameError} helperText={lastNameError}  />
                            <TextField value={props.email} onChange={(event) => {props.setEmail(event.target.value)}} onBlur={(event) => {validateForm('email')}} margin="normal" required fullWidth id="outlined-basic" label="Email" variant="outlined" error={!!emailError} helperText={emailError}  />

                            <Box sx={{marginTop: '15px', display: 'flex',}}>
                                <Box><Button onClick={(event) => {clickSave()}} disabled={!!firstNameError || !!lastNameError || !!emailError} sx={{ padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >Save</Button></Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </>
        )
    }
}

export default PeopleForm;
