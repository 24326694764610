import { useState } from 'react'
import { Link, useNavigate} from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridActionsCellItem, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import GenericConfirm from '../Generic/GenericConfirm';

function EditToolbar(props) {
  const { setEditOpen, setSelectedSystem } = props;

  const handleClick = () => {
    setEditOpen(true, -1)
    setSelectedSystem(-1)
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

export default function IdentityProviderGrid(props) {
  const [deletingPopup, setDeletingPopup] = useState({show: false, id: null, name: null});
  const navigate = useNavigate();

  const handleCellDoubleClick = (params, event) => {
    navigate("/identityprovider/"+params.id);
  }

  const handleEditClick = (id) => () => {
    navigate("/identityprovider/"+id);
  };

  const handleDeleteClick = (id) => () => {
    const deletingRow = props.rows.find((row) => row.id === id);
    setDeletingPopup({
      show: true,
      id: deletingRow.id,
      name: deletingRow.name
    })
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      editable: false,
      renderCell: ({ row }) => (
        <>
          <Link to={'/identityprovider/'+row.id} style={{color: 'black'}}>{row.name}</Link>
        </>
      ),
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 350,
      editable: false,
    },
    {
      field: 'owner',
      headerName: 'Owner',
      width: 250,
      editable: false,
    },
    {
      field: 'owneremail',
      headerName: 'Owner Email',
      width: 250,
      editable: false,
    },
    {
      field: 'created',
      headerName: 'Created',
      width: 280,
      editable: false,
    },
    {
      field: 'updated',
      headerName: 'Updated',
      width: 280,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleCloseConfirmDeleteConfirm = () => {
    fetch(process.env.REACT_APP_API_URL+'/api/system/'+deletingPopup.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if(!response.ok)
          return false
      else
          return response.json()
    }).then((response) => {
      if(response) {
        if(response.error) {
            props.raiseAlert('error', response.error);
        } else {
          props.setRows(props.rows.filter((row) => row.id !== deletingPopup.id));

          setDeletingPopup({
            show: false,
            id: '',
            name: ''
          })
    
          props.raiseAlert('success', 'Deleted record');
        }
      } else {
          props.raiseAlert('error', 'Error deleting record');
      }      
    }).catch(function (error) {
        props.raiseAlert('error', 'Error deleting record')
    });

  };
    
  const handleCloseConfirmDeleteCancel = () => {
    setDeletingPopup({
      show: false,
      id: '',
      name: ''
    })
  };

  if (props.isLoading) {
    return (
      <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid
          rows={props.rows}
          columns={columns}
          rowHeight={25}
          onCellDoubleClick={handleCellDoubleClick}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setEditOpen: props.setEditOpen, setSelectedSystem: props.setSelectedSystem },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
            columns: {
              columnVisibilityModel: {
                name: true,
                notes: true,
                owneremail: true,
                created: false,
                updated: false,
                actions: true
              },
            },
            pagination: { paginationModel: { pageSize: 25 } },
          }}
        />
        <GenericConfirm 
          open={deletingPopup.show} 
          handleClose={handleCloseConfirmDeleteCancel} 
          title="Confirm delete"
          text={<>Confirm delete of identity provider <b>{deletingPopup.name}</b>?</>}
          handleCloseOK={handleCloseConfirmDeleteConfirm}
          handleCloseCancel={handleCloseConfirmDeleteCancel}
          />

      </Box>
    );
  }
}