import { useState } from 'react'
import ReactGA from 'react-ga4';
import { Grid, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import PricingColumn from './PricingColumn';

function Pricing(props) {    
    const pricingData = {
        'basic': {
            'header': 'BASIC',
            'description': '1-9 employees',
            'color': '#B37BD1',
            'border': 'solid black',
            'borderWidth': '0px 0px 0px 0px !important',
            'min': 1,
            'max': 9,
            'monthly': props.rawPricing['basic']['monthly'],
            'yearly': props.rawPricing['basic']['yearly'],
            'buttonID': 'basic',
            'monthly_pppm': '$' + Math.round((props.rawPricing['basic']['monthly']/9) * 100) / 100,
            'yearly_pppm': '$' + Math.round((props.rawPricing['basic']['yearly']/12/9) * 100) / 100,
            'duration': '0.4',
            'xs': 12,
            'sm': 6,
            'md': 6,
            'lg': (1/5)*12,
            'xl': (1/5)*12,
            'final_transition': false
        },
        'standard': {
            'header': 'STANDARD',
            'description': '10-49 employees',
            'color': '#B37BD1',
            'border': 'solid black',
            'borderWidth': '0px 0px 0px 0px !important',
            'min': 10,
            'max': 49,
            'monthly': props.rawPricing['standard']['monthly'],
            'yearly': props.rawPricing['standard']['yearly'],
            'buttonID': 'standard',
            'monthly_pppm': '$' + Math.round((props.rawPricing['standard']['monthly']/49) * 100) / 100,
            'yearly_pppm': '$' + Math.round((props.rawPricing['standard']['yearly']/12/49) * 100) / 100,
            'duration': '0.6',
            'xs': 12,
            'sm': 6,
            'md': 6,
            'lg': (1/5)*12,
            'xl': (1/5)*12,
            'final_transition': false
        },
        'pro': {
            'header': 'PRO',
            'description': '50-99 employees',
            'color': '#B37BD1',
            'border': 'solid black',
            'borderWidth': '0px 0px 0px 0px !important',
            'min': 50,
            'max': 99,
            'monthly': props.rawPricing['pro']['monthly'],
            'yearly': props.rawPricing['pro']['yearly'],
            'buttonID': 'pro',
            'monthly_pppm': '$' + Math.round((props.rawPricing['pro']['monthly']/99) * 100) / 100,
            'yearly_pppm': '$' + Math.round((props.rawPricing['pro']['yearly']/12/99) * 100) / 100,
            'duration': '0.8',
            'xs': 12,
            'sm': 6,
            'md': 6,
            'lg': (1/5)*12,
            'xl': (1/5)*12,
            'final_transition': false
        },
        'proplus': {
            'header': 'PRO PLUS',
            'description': '100-249 employees',
            'color': '#B37BD1',
            'border': 'solid black',
            'borderWidth': '0px 0px 0px 0px !important',
            'min': 100,
            'max': 249,
            'monthly': props.rawPricing['proplus']['monthly'],
            'yearly': props.rawPricing['proplus']['yearly'],
            'buttonID': 'proplus',
            'monthly_pppm': '$' + Math.round((props.rawPricing['proplus']['monthly']/249) * 100) / 100,
            'yearly_pppm': '$' + Math.round((props.rawPricing['proplus']['yearly']/12/249) * 100) / 100,
            'duration': '1.0',
            'xs': 12,
            'sm': 6,
            'md': 6,
            'lg': (1/5)*12,
            'xl': (1/5)*12,
            'final_transition': false
        },
        'enterprise': {
            'header': 'ENTERPRISE',
            'description': '250+ Employees',
            'color': '#B37BD1',
            'border': 'solid black',
            'borderWidth': '0px 0px 0px 0px !important',
            'min': 250,
            'max': 9999,
            'monthly': ' Call Us',
            'yearly': ' Call Us',
            'buttonID': 'enterprise',
            'monthly_pppm': '$ Call Us',
            'yearly_pppm': '$ Call Us',
            'duration': '1.2',
            'xs': 12,
            'sm': 12,
            'md': 12,
            'lg': (1/5)*12,
            'xl': (1/5)*12,
            'final_transition': true
        },
    }
    
    const [pricing, setPricing] = useState('monthly');
    const [alreadyAnimated, setAlreadyAnimated] = useState(false);
    
    const handlePricingChange = (event, newPricing) => {
        if (newPricing) {
            setAlreadyAnimated(false)
            setPricing(newPricing);
            // Track the click event
            ReactGA.event({
                category: 'Button',
                action: 'Click',
                label: 'Change Pricing - ' + newPricing
            });
        }
    };

    

    return (
        <>
            <h1 style={{lineHeight: 1.1, fontSize: '40px', color: '#40434E'}}>Pricing</h1>
            <Box sx={{marginBottom: '15px'}}>
                <ToggleButtonGroup
                value={pricing}
                exclusive
                onChange={handlePricingChange}
                aria-label="Platform"
                >
                <ToggleButton value="monthly">Monthly</ToggleButton>
                <ToggleButton value="yearly">Yearly</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box>
                <Grid sx={{padding: {xs: '0px 60px 0px 60px',sm: '0px 50px 0px 50px',md: '0px 150px 0px 150px',lg: '0px 30px 0px 30px',xl:'0px 30px 0px 30px'}}} container spacing={1}>
                {Object.keys(pricingData).map((keyName, i) => (
                    <PricingColumn StyledButton={props.StyledButton} alreadyAnimated={alreadyAnimated} setAlreadyAnimated={setAlreadyAnimated} handlePricingSignupButtonClick={props.handlePricingSignupButtonClick} key={i} height='400px' buttonID={pricingData[keyName]['buttonID']+'_'+pricing} pricingTerm={pricing} duration={pricingData[keyName]['duration']} border={pricingData[keyName]['border']} xs={pricingData[keyName]['xs']} sm={pricingData[keyName]['sm']} md={pricingData[keyName]['md']} lg={pricingData[keyName]['lg']} xl={pricingData[keyName]['xl']} final_transition={pricingData[keyName]['final_transition']} borderWidth={pricingData[keyName]['borderWidth']} color={pricingData[keyName]['color']} header={pricingData[keyName]['header']} price={ pricingData[keyName][pricing] } unitprice={pricingData[keyName][pricing+'_pppm']} description={pricingData[keyName]['description']} />
                ))}
                </Grid>
            </Box>
        </>
    )
}

export default Pricing;
