'use client'
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

function Menu(props) {
  if (props.isAdmin) {
    return (
      <List>
        <ListItem key={'nav_settings_profile'} disablePadding>
          <ListItemButton sx={{backgroundColor: props.activeNav === 'profile' ? '#f0f0f0' : ''}} onClick={() => props.setActiveNav('profile')} LinkComponent={Link} to='/settings/profile'>
            <ListItemText sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' }}} primary='Edit Profile' />
            <ListItemText sx={{ display: { md: 'block', lg: 'none' }}} primary='Profile' />
          </ListItemButton>
        </ListItem>
        <ListItem key={'nav_settings_users'} disablePadding>
          <ListItemButton sx={{backgroundColor: props.activeNav === 'users' ? '#f0f0f0' : ''}} onClick={() => props.setActiveNav('users')} LinkComponent={Link} to='/settings/users'>
            <ListItemText sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' }}} primary='Add/Edit Users' />
            <ListItemText sx={{ display: { md: 'block', lg: 'none' }}} primary='Users' />
          </ListItemButton>
        </ListItem>
        <ListItem key={'nav_settings_subscription'} disablePadding>
          <ListItemButton sx={{backgroundColor: props.activeNav === 'subscription' ? '#f0f0f0' : ''}} onClick={() => props.setActiveNav('subscription')} LinkComponent={Link} to='/settings/subscription'>
            <ListItemText sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' }}} primary='Manage Subscription' />
            <ListItemText sx={{ display: { md: 'block', lg: 'none' }}} primary='Subscription' />
          </ListItemButton>
        </ListItem>
      </List>
    );
  }
}

export default Menu;