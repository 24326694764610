'use client'
import { useState } from 'react'
import { TextField, Grid, InputLabel, Typography, Button, Box, CircularProgress } from '@mui/material';

function EditProfile(props) {
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);

    const [currentPasswordError, setCurrentPasswordError] = useState(null);
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [newPasswordConfirmError, setNewPasswordConfirmError] = useState(null);

    const [isSavingProfile, setIsSavingProfile] = useState(false);
    const [isSavingPassword, setIsSavingPassword] = useState(false);
    
    function validateForm(field) {
        var anyError = false;
        if (field === 'first_name') {
            if (props.firstName.length < 1) {
                setFirstNameError('First name must exist')
                anyError = true;
            }
            else {
                setFirstNameError(null)
            }
        }
        if (field === 'last_name') {
            if (props.lastName.length < 1) {
                setLastNameError('Last name must exist')
                anyError = true;
            }
            else {
                setLastNameError(null)
            }
        }
        if (field === 'email') {
            var validEmailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (props.email.match(validEmailRegex)) {
                setEmailError(null)
            } else {
                setEmailError('Invalid email address')
                anyError = true;
            }
        }
        if (field === 'current_password') {
            if (props.currentPassword.length < 1) {
                setCurrentPasswordError('Please enter your current password')
                anyError = true;
            }
            else {
                setCurrentPasswordError(null)
            }
        }
        if (field === 'new_password') {
            var validPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
            if (props.newPassword.match(validPasswordRegex)) {
                setNewPasswordError(null)
            } else {
                setNewPasswordError('Password must contain at least 8 characters, including one letter, one number and one special character')
                anyError = true;
            }
        }
        if (field === 'new_password_confirm') {
            if (props.newPassword !== props.newPasswordConfirm) {
                setNewPasswordConfirmError('Both passwords must match')
                anyError = true;
            } else {
                setNewPasswordConfirmError(null)
            }
        }

        return anyError;
    }

    function clickSaveProfile() {
        setIsSavingProfile(true)
        validateForm('first_name');
        validateForm('last_name');
        validateForm('email');
        
        if (firstNameError || lastNameError || emailError) {
            props.raiseAlert('error', 'Error whilst saving profile')
            setIsSavingProfile(false)
            return false;
        }
        else {
            const post_data = {
                'family_name': props.lastName,
                'given_name': props.firstName,
                'email': props.email
            }
            
            const post_headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

            fetch(process.env.REACT_APP_API_URL+'/api/profile', {
                method: 'put',
                body: new URLSearchParams(post_data),
                headers: post_headers
                }).then((response) => {
                    if(!response.ok)
                        return false
                    else
                        return response.json()
                }).then((response) => {
                    if(response) {
                        if(response.error) {
                            props.raiseAlert('error', response.error);
                        } else {
                            if (response.data.changed_email) {
                                props.raiseAlert('success', 'Updated profile')
                                props.raiseAlert('warning', 'Please check your email to verify email')
    
                            } else {
                                props.raiseAlert('success', 'Updated profile')
                            }
                        }
                    } else {
                        props.raiseAlert('error', 'Error updating profile');
                    }
                    setIsSavingProfile(false)
                })
                .catch(function (error) {
                    props.raiseAlert('error', 'Error updating profile');
                    setIsSavingProfile(false)
                });
        }
    }

    function clickSavePassword() {
        setIsSavingPassword(true)
        validateForm('current_password');
        validateForm('new_password');
        validateForm('new_password_confirm');
        
        if (currentPasswordError || newPasswordError || newPasswordConfirmError) {
            props.raiseAlert('error', 'Error whilst changing password')
            setIsSavingPassword(false)
            return false;
        }
        else {
            const post_data = {
                'current_password': props.currentPassword,
                'new_password': props.newPassword,
                'new_password_confirm': props.newPasswordConfirm
            }
            
            const post_headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

            fetch(process.env.REACT_APP_API_URL+'/api/change_password', {
                method: 'put',
                body: new URLSearchParams(post_data),
                headers: post_headers
                }).then((response) => {
                    if(!response.ok)
                        return false
                    else
                        return response.json()
                }).then((response) => {
                    if(response) {
                        if(response.error) {
                            props.raiseAlert('error', response.error);
                        } else {
                            props.raiseAlert('success', 'Updated password')
                        }
                    } else {
                        props.raiseAlert('error', 'Error updating password');
                    }
                    setIsSavingPassword(false)
                })
                .catch(function (error) {
                    props.raiseAlert('error', 'Error updating password');
                });
        }
    }

    return (
        <>
            <Typography sx={{marginBottom: '20px'}} variant='h6'>Edit Profile</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{maxWidth: '350px', width: '100%'}}>
                        <TextField
                        style={{ margin: "5px" }}
                        type="text"
                        label="First Name"
                        variant="outlined"
                        value={props.firstName}
                        onChange={(event) => {props.setFirstName(event.target.value)}} 
                        onBlur={(event) => {validateForm('first_name')}}
                        error={!!firstNameError} 
                        helperText={firstNameError}
                        required
                        fullWidth
                        />
                    </div>
                    <div style={{maxWidth: '350px', width: '100%'}}>
                        <TextField
                        style={{ margin: "5px" }}
                        type="text"
                        label="Last Name"
                        variant="outlined"
                        value={props.lastName}
                        onChange={(event) => {props.setLastName(event.target.value)}} 
                        onBlur={(event) => {validateForm('last_name')}}
                        error={!!lastNameError} 
                        helperText={lastNameError}
                        required
                        fullWidth
                        />
                    </div>
                    <div style={{maxWidth: '350px', width: '100%'}}>
                        <TextField
                        style={{ margin: "5px" }}
                        type="text"
                        label="Email"
                        variant="outlined"
                        value={props.email}
                        onChange={(event) => {props.setEmail(event.target.value)}} 
                        onBlur={(event) => {validateForm('email')}}
                        error={!!emailError} 
                        helperText={emailError}
                        required
                        fullWidth
                        />
                    </div>
                    <Button 
                        onClick={(event) => {clickSaveProfile()}} 
                        sx={{ width: '200px', padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} 
                        disabled={isSavingProfile}
                        >{isSavingProfile ? <CircularProgress size={'24px'} /> : 'Save Profile'}</Button>
                </Grid>
                <Grid sx={{marginTop: '20px'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel sx={{fontSize: '0.9rem'}}>Password</InputLabel>
                    <div style={{maxWidth: '350px', width: '100%'}}>
                        <TextField
                        style={{ margin: "5px" }}
                        type="password"
                        label="Current Password"
                        variant="outlined"
                        value={props.currentPassword}
                        onChange={(event) => {props.setCurrentPassword(event.target.value)}} 
                        onBlur={(event) => {validateForm('current_password')}}
                        error={!!currentPasswordError} 
                        helperText={currentPasswordError}
                        required
                        fullWidth
                        />
                    </div>
                    <div style={{maxWidth: '350px', width: '100%'}}>
                        <TextField
                        style={{ margin: "5px" }}
                        type="password"
                        label="New Password"
                        variant="outlined"
                        value={props.newPassword}
                        onChange={(event) => {props.setNewPassword(event.target.value)}} 
                        onBlur={(event) => {validateForm('new_password'); validateForm('new_password_confirm')}}
                        error={!!newPasswordError} 
                        helperText={newPasswordError}
                        required
                        fullWidth
                        />
                    </div>
                    <div style={{maxWidth: '350px', width: '100%'}}>
                        <TextField
                        style={{ margin: "5px" }}
                        type="password"
                        label="Confirm New Password"
                        variant="outlined"
                        value={props.newPasswordConfirm}
                        onChange={(event) => {props.setNewPasswordConfirm(event.target.value)}} 
                        onBlur={(event) => {validateForm('new_password_confirm')}}
                        error={!!newPasswordConfirmError} 
                        helperText={newPasswordConfirmError}
                        required
                        fullWidth
                        />
                    </div>
                    <Button 
                        onClick={(event) => {clickSavePassword()}} 
                        sx={{ width: '200px', padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} 
                        disabled={isSavingPassword}
                        >{isSavingPassword ? <CircularProgress size={'24px'} /> : 'Save Password'}</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{backgroundColor: '#f8f8f8', width: '100%', maxWidth: '350px', padding: '16px'}}>
                        <Typography sx={{fontSize: '0.9rem', marginBottom: '20px'}} variant='h6'>Password Requirements</Typography>
                        <ol style={{fontSize: '0.8rem', lineHeight: '1.4rem'}}>
                            <li>At least 8 characters</li>
                            <li>At least 1 number</li>
                            <li>At least 1 symbol</li>
                        </ol>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default EditProfile;