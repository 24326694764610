import { useState, useEffect } from 'react'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { Box, Typography, CircularProgress } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '../components/Settings/Menu';
import EditProfile from '../components/Settings/EditProfile';
import EditUsers from '../components/Settings/EditUsers';
import ManageSubscription from '../components/Settings/ManageSubscription';
import { validateLoggedInUser } from '../components/Generic/ValidateLoggedInUser';

const drawerWidth = 180;
const drawerWidthSmall = 120;

function Settings() {
  const [activeNav, setActiveNav] = useState('profile')
  const { selectedNav } = useParams();
  const [isAdmin, setIsAdmin] = useState(false)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [isLoading, setLoading] = useState(true)
  const raiseAlert = useOutletContext();
  const navigate = useNavigate();

  // Set the selected navigation based on URL param
  useEffect(() => {
    if (selectedNav) {
      setActiveNav(selectedNav);
    } else {
      navigate('/settings/profile');
    }
  }, [selectedNav, navigate]);

  const displayComponent = (activeNav) => {
    switch (activeNav) {
      case 'users':
        return <EditUsers />;
      case 'subscription':
        return <ManageSubscription />;
      case 'profile':
      default:
        return <EditProfile
        raiseAlert={raiseAlert}
        firstName={firstName}
        lastName={lastName}
        email={email}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        newPasswordConfirm={newPasswordConfirm}
        setNewPasswordConfirm={setNewPasswordConfirm}
      />;
    }
  };

  useEffect(() => {
    const loadPage = async () => {
      const validationStatus = await validateLoggedInUser();
      if (validationStatus === 'Unauthorized') {
        fetch(process.env.REACT_APP_API_URL+'/api/logout', {
          method: 'post',
          }).then(res => {
            window.location.href = '/'
          }).catch(function (error) {
            window.location.href = '/'
        });
      } else {
        fetch(process.env.REACT_APP_API_URL+'/api/profile')
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
                setFirstName(response.data.given_name)
                setLastName(response.data.family_name)
                setEmail(response.data.email)
                setIsAdmin(response.data.app_metadata.loginregister_admin)
            }
          } else {
              raiseAlert('error', 'Error loading profile');
          }
          
          setLoading(false)
        })
        .catch(function (error) {
          setLoading(false)
          raiseAlert('error', 'Error loading profile')
        });
      }
    };
    loadPage();
  }, [])

  if (isLoading) {
    return (
      <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }
  else {
    return (
      <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
        <Typography variant="h5" component="div" sx={{}}><SettingsIcon sx={{marginTop: '-5px', verticalAlign: 'middle', marginRight: '20px'}} />Settings</Typography>
        <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Manage your account settings here</Typography>
        <Box sx={{p: 1, }}>
          <Box sx={{ display: 'flex' }}>
            <Box
              component="nav"
              sx={{ display: isAdmin ? 'block' : 'none', width: { md: drawerWidthSmall, lg: drawerWidth }, flexShrink: { sm: 0 } }}
            >
              <Menu isAdmin={isAdmin} activeNav={activeNav} setActiveNav={setActiveNav}/>
            </Box>
            <Box
              component="main" p={2}
              sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
              {displayComponent(activeNav)}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
}

export default Settings;
