import { useState } from 'react'
import { Grid, Box } from '@mui/material';
import { motion } from "framer-motion";

function FadeIn({ alreadyExecuted, setAlreadyExecuted, children }) {

    if (alreadyExecuted) {
        return <>{children}</>
    } else {
        return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: '1.0' }}
            variants={{
            visible: { opacity: 1, marginTop: 0 },
            hidden: { opacity: 0, marginTop: 0 }
            }}
            onAnimationComplete={() => {setAlreadyExecuted(true)}}
        >
            {children}
        </motion.div>
        );
    }
}
  
function ProductInfoTileDetails(props) {
    const [detailShown, setDetailShown] = useState(false);
    const [alreadyExecuted, setAlreadyExecuted] = useState(false);

    function clickLearnMore(event) {
        setAlreadyExecuted(false);
        event.preventDefault();
        setDetailShown(!detailShown);
        return false;
    }

    return (
        <>
            <a href='#' onClick={(event) => {return clickLearnMore(event)}}>Learn more</a>
            <FadeIn alreadyExecuted={alreadyExecuted} setAlreadyExecuted={setAlreadyExecuted}>
                <p onClick={(event) => {return clickLearnMore(event)}} style={{padding: '0px 10px', cursor: 'pointer', height: '210px', overflowY: 'scroll', display: (detailShown ? 'block' : 'none')}}>
                    {props.description}
                </p>
                <div 
                    onClick={(event) => {return clickLearnMore(event)}} 
                    style={{
                        cursor: 'pointer', 
                        marginTop: '16px', 
                        display: (detailShown ? 'none' : 'block'), 
                        filter: 'drop-shadow(4px 4px 10px #e3e3e3)',
                        width: '100%',
                        height: '100%',
                        backgroundImage: "url("+props.img+')',
                        backgroundSize: props.backgroundSize,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: props.backgroundPosition,
                        overflowY: 'clip'
                    }}>
                </div>
            </FadeIn>
                    
        </>
    )
}

function FadeInWhenVisible({ children }) {
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: '2.0' }}
            variants={{
            visible: { opacity: 1, marginTop: 0 },
            hidden: { opacity: 0, marginTop: 40 }
            }}
            style={{height: '100%'}}
        >
            {children}
        </motion.div>
    );}

function ProductInfoTile(props) {
    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <FadeInWhenVisible>
                <Box sx={{height: '100%', borderRadius: '15px', backgroundColor: 'white'}}>
                    <img style={{borderRadius: '25px', padding: '10px'}} src={props.img} width="100%"></img>
                    <header style={{paddingLeft: '20px', textAlign: 'left', fontWeight: 'bold', fontSize: '14px'}}>{props.header}</header>
                    <p style={{ textAlign: 'left', paddingLeft: '20px', paddingRight: '20px', fontWeight: '200', fontSize: '14px'}}>{props.description}</p>
                    
                </Box>
                </FadeInWhenVisible>
        </Grid>
    )
}

export default ProductInfoTile;
