import { useState } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Accounts from './pages/Accounts.js';
import People from './pages/People.js';
import Settings from './pages/Settings.js';
import Systems from './pages/Systems.js';
import IdentityProviders from './pages/IdentityProviders.js'
import IdentityProviderDetail from './pages/IdentityProviderDetail.js';
import Home from './pages/Home.js';
import Legal from './pages/Legal.js';
import SystemDetail from './pages/SystemDetail.js';
import PeopleDetail from './pages/PeopleDetail.js';
import NetworkChart from './pages/NetworkChart.js';
import Reports from './pages/Reports.js';
import Audit from './pages/Audit.js';
import SubscriptionExpired from './pages/SubscriptionExpired.js';
import Navbar from './components/Navbar/Navbar';
import AlertStack from './components/Alerts/AlertStack.js';
import AlertInstance from './components/Alerts/AlertInstance.js';
import PageTracking from './components/Generic/PageTracking.js';
import { LandingPageSpreadsheets, LandingPageModern, LandingPageISO, LandingPageACM } from './pages/LandingPages.js';

// Initialize Google Analytics
ReactGA.initialize("G-66NK397J4C", {
  gaOptions: {
    debug_mode: true,
  },
  gtagOptions: {
    debug_mode: true,
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#99D17B',
      light: '#b2dc9b',
      dark: '#8aca67',
      contrastText: '#40434E',
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.8rem',
        },
        secondary: {
          fontSize: '0.6rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      }
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <PageTracking />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/systems" element={<Systems />} />
          <Route path="/identityproviders" element={<IdentityProviders />} />
          <Route path="/system/:id" element={<SystemDetail />} />
          <Route path="/identityprovider/:id" element={<IdentityProviderDetail />} />
          <Route path="/people" element={<People />} />
          <Route path="/people/:id" element={<PeopleDetail />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/:selectedNav" element={<Settings />} />
          <Route path="/networkchart" element={<NetworkChart />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/audit" element={<Audit />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/subscriptionexpired" element={<SubscriptionExpired />} />
          <Route path="/no-more-spreadsheets" element={<LandingPageSpreadsheets />} />
          <Route path="/modern" element={<LandingPageModern />} />
          <Route path="/iso27001" element={<LandingPageISO />} />
          <Route path="/access-control-matrix" element={<LandingPageACM />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

function Layout() {
  const [alerts, setAlerts] = useState([]);

  function raiseAlert(type, message) {
    setAlerts((alerts) => [...alerts, <AlertInstance type={type} message={message} timeout={15000} />]);
  }

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <Navbar />
        <Box
          component="main"
          sx={{ flexGrow: 1, width: { sm: `calc(100% - ${240}px)` } }}
        >
          <AlertStack alerts={alerts} />
          <Outlet context={raiseAlert} />
        </Box>
      </Box>
    </>
  );
}

export default App;