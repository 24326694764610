import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactGA from 'react-ga4';

function FAQ() {
    const handleFaqClick = (event) => {
        const faqLabel = event.target.textContent;
        ReactGA.event({
          category: 'FAQ',
          action: 'Click',
          label: faqLabel,
        });
      };
    return (
        <>
            <h1 style={{lineHeight: 1.1, fontSize: '40px', color: '#40434E'}}>FAQ</h1>
            <Box sx={{maxWidth: '800px', margin: 'auto'}}>
                <Accordion disableGutters>
                    <AccordionSummary sx={{textAlign: 'left', fontWeight: '500', fontSize: '16px'}}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleFaqClick}
                    >
                    How does LoginRegister help with Cyber Security compliance?
                    </AccordionSummary>
                    <AccordionDetails sx={{textAlign: 'left', fontWeight: '300'}}>
                        By tracking which of your employees have access to what systems you are able to ensure accounts are disabled when no longer required - a fundamental requirement of many Cyber Security standards including Cyber Essentials (“Section 4 - User access control”), ISO27001 (“Annex A.9 - Access Control”) and SOC2 (“Security Trust Service Principle”). Furthermore, LoginRegister supports accounts where more than one person has knowledge of the credentials, enabling Administrators to ensure passwords are changed if one of the users leaves the business or changes role. Lastly, with flexible reporting, LoginRegister vastly simplifies the requirement to audit user access, such as necessitated by Annex A.9.2 of ISO27001 and the Security principle of SOC2.
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters>
                    <AccordionSummary sx={{textAlign: 'left', fontWeight: '500', fontSize: '16px'}}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleFaqClick}
                    >
                    Where is my data stored?
                    </AccordionSummary>
                    <AccordionDetails sx={{textAlign: 'left', fontWeight: '300'}}>
                        LoginRegister is hosted in the eu-west-2 region of AWS, located in London, UK. All data is encrypted in transit and at rest.
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters>
                    <AccordionSummary sx={{textAlign: 'left', fontWeight: '500', fontSize: '16px'}}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleFaqClick}
                    >
                    Do you offer any discounts?
                    </AccordionSummary>
                    <AccordionDetails sx={{textAlign: 'left', fontWeight: '300'}}>
                        Yes, customers who opt to pay yearly benefit from one month free across the year, a saving of about 8%. 
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters>
                    <AccordionSummary sx={{textAlign: 'left', fontWeight: '500', fontSize: '16px'}}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleFaqClick}
                    >
                    Can I try before I buy?
                    </AccordionSummary>
                    <AccordionDetails sx={{textAlign: 'left', fontWeight: '300'}}>
                    Yes, we offer a 14-day fully-featured trial.
                    </AccordionDetails>
                </Accordion>
                <Accordion disableGutters>
                    <AccordionSummary sx={{textAlign: 'left', fontWeight: '500', fontSize: '16px'}}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleFaqClick}
                    >
                    What payment methods are accepted?
                    </AccordionSummary>
                    <AccordionDetails sx={{textAlign: 'left', fontWeight: '300'}}>
                        We accept payment by all major credit cards.
                    </AccordionDetails>
                </Accordion>                
                <Accordion disableGutters>
                    <AccordionSummary sx={{textAlign: 'left', fontWeight: '500', fontSize: '16px'}}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleFaqClick}
                    >
                    How do I get additional support or ask another question?
                    </AccordionSummary>
                    <AccordionDetails sx={{textAlign: 'left', fontWeight: '300'}}>
                        Feel free to reach out to us at info@loginregister.com and we'll get back to you.
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    )
}

export default FAQ;
