import { useState, useEffect } from 'react'
import { Alert } from '@mui/material';

function AlertInstance(props) {
    const [show, setShow] = useState(true)

    useEffect(() => {
        const timeId = setTimeout(() => {
            setShow(false)
        }, props.timeout)

        return () => {
            clearTimeout(timeId)
        }
    }, [props.timeout,]);

    if (!show) {
        return null;
    }

    return (
        <Alert sx={{'border': 1}} severity={props.type} onClose={() => {setShow(false);}}>{props.message}</Alert>
    )
}

export default AlertInstance
