'use client'
import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Typography, Box } from '@mui/material';
import UsersGrid from './Users/UsersGrid';
import UsersForm from './Users/UsersForm';
import GenericConfirm from '../Generic/GenericConfirm';

function EditUsers(props) {
  const raiseAlert = useOutletContext();
  const [editOpen, setEditOpen] = useState(false);
  const [editConfirmationOpen, setEditConfirmationOpen] = useState(false);
  const [selectedRow, setselectedRow] = useState(-1);
  const [isLoading, setLoading] = useState(true)
  const [rows, setRows] = useState([]);

  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');
  const [email, setEmail] = useState('');
  const [blocked, setBlocked] = useState('false');
  const [emailVerified, setEmailVerified] = useState('false');
  const [isAdmin, setIsAdmin] = useState('false');
  
  const handleEditOpen = (openVal, selectedID) => {
    if (selectedID === -1) {
      setFamilyName('')
      setGivenName('')
      setEmail('')
      setBlocked('false')
      setEmailVerified('false')
      setIsAdmin('false')
    }
    else {
      const rowEdit = rows.find((row) => row.id === selectedID);
      setFamilyName(rowEdit.family_name)
      setGivenName(rowEdit.given_name)
      setEmail(rowEdit.email)
      setBlocked(rowEdit.blocked)
      setEmailVerified(rowEdit.email_verified)
      setIsAdmin(rowEdit.app_metadata.loginregister_admin)
    }
    setEditOpen(openVal)
  }

  const handleFormClose = () => {
    setEditConfirmationOpen(true)
  };

  const handleCompleteSave = () => {
    setEditConfirmationOpen(false)
    setEditOpen(false)
  };

  const handleFormConfirmationCloseCancel = (event) => {
    setEditConfirmationOpen(false)
  };

  const handleFormConfirmationCloseOK = (event) => {
    setEditConfirmationOpen(false)
    setEditOpen(false)
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL+'/api/users')
      .then((response) => {
        if(!response.ok)
            return false
        else
            return response.json()
      })
      .then((response) => {
        if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
              setRows(response.data)
            }
        } else {
            raiseAlert('error', 'Error loading users')
        }
        setLoading(false)
      })
      .catch(function (error) {
        raiseAlert('error', 'Error loading users')
        setLoading(false)
    });
  }, [])
  
  return (
    <>
        <Typography sx={{marginBottom: '20px'}} variant='h6'>Edit Users</Typography>
        <Box sx={{p: 1, }}>
        <UsersForm 
          open={editOpen} 
          handleClose={handleFormClose} 
          handleCompleteSave={handleCompleteSave}
          selectedRow={selectedRow} 
          familyName={familyName}
          setFamilyName={setFamilyName}
          givenName={givenName}
          setGivenName={setGivenName}
          email={email}
          setEmail={setEmail}
          blocked={blocked}
          setBlocked={setBlocked}
          emailVerified={emailVerified}
          isAdmin={isAdmin}
          setIsAdmin={setIsAdmin}
          raiseAlert={raiseAlert}
          rows={rows}
          setRows={setRows}
          />
        <GenericConfirm title='Cancel changes?' text='Are you sure you want to cancel your changes?' handleCloseOK={handleFormConfirmationCloseOK} handleCloseCancel={handleFormConfirmationCloseCancel} open={editConfirmationOpen} />
        <UsersGrid 
          rows={rows}
          setRows={setRows}
          raiseAlert={raiseAlert}
          editOpen={editOpen}
          setEditOpen={handleEditOpen}
          setselectedRow={setselectedRow}
          isLoading={isLoading}
          />
      </Box>
    </>
  );
}

export default EditUsers;