import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { validateLoggedInUser } from '../components/Generic/ValidateLoggedInUser';
import ReportGrid from '../components/Reports/ReportGrid';

function Reports() {
  const navigate = useNavigate();
  const raiseAlert = useOutletContext();
  const [isLoading, setLoading] = useState(true)
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const loadPage = async () => {
      const validationStatus = await validateLoggedInUser();
      if (validationStatus === 'Unauthorized') {
        fetch(process.env.REACT_APP_API_URL+'/api/logout', {
          method: 'post',
          }).then(res => {
            window.location.href = '/'
          }).catch(function (error) {
            window.location.href = '/'
        });
      } else if (validationStatus === 'TrialExpired') {
        navigate('/subscriptionexpired');
      } else {
        fetch(process.env.REACT_APP_API_URL+'/api/report')
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
                setRows(response.data)
            }
          } else {
              raiseAlert('error', 'Error loading report');
          }
          setLoading(false)
        })
        .catch(function (error) {
          raiseAlert('error', 'Error loading report')
          setLoading(false)
        });
      }
    };
    loadPage();
  }, [])

  return (
    <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
      <Typography variant="h5" component="div" sx={{}}><EqualizerIcon sx={{verticalAlign: 'middle', marginRight: '20px'}}/>Reports</Typography>
      <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Report on your enterprise</Typography>
      <Box sx={{p: 1, }}>
        <ReportGrid rows={rows} isLoading={isLoading} />
      </Box>
    </Box>
  )
}

export default Reports;
