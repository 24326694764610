import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function GenericConfirm(props) {
    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
            >
                <DialogTitle>
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={props.handleCloseOK}>Yes</Button>
                    <Button color='secondary' onClick={props.handleCloseCancel} autoFocus>No</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default GenericConfirm;
