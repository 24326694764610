import { Modal, Box, Button } from '@mui/material';
import Registration from './Registration';
import CloseIcon from '@mui/icons-material/Close';

function RegistrationModal(props) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '100vh',
        overflowY: 'auto'
      };

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            sx={{}}
        >
            <Box component="form" sx={style} >
                <div style={{float: 'right'}}><Button sx={{minWidth: '30px'}} onClick={props.handleClose}><CloseIcon/></Button></div>
                <Registration {...props} />
            </Box>
        </Modal>
    )
}

export default RegistrationModal;
