import { useOutletContext } from 'react-router-dom'
import Cookies from 'js-cookie';
import HomeAuthed from '../components/Home/HomeAuthed'
import HomeNotAuthed from '../components/Home/HomeNotAuthed'

function Home() {
  const raiseAlert = useOutletContext();
  if ((Cookies.get('auth0Accesstoken') && Cookies.get('auth0Accesstoken') !== "")) {
    return (
      <HomeAuthed raiseAlert={raiseAlert}/>
    );
  }
  else {
    return (
      <HomeNotAuthed raiseAlert={raiseAlert} />
    )
  }
}

export default Home;
