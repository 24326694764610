import { useState, useEffect } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { Box, AppBar, Toolbar, Grid, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import Registration from '../../components/Home/Registration';
import { validateLoggedInUser } from '../../components/Generic/ValidateLoggedInUser';

function LandingPage(props) {
  const navigate = useNavigate();
  const raiseAlert = useOutletContext();
  const [selectedPrice, setSelectedPrice] = useState('basic')
  
  const pricing = {
    'basic': {
        'monthly': 35, 
        'yearly': 35*11
    },
    'standard': {
        'monthly': 90, 
        'yearly': 90*11
    },
    'pro': {
        'monthly': 160, 
        'yearly': 160*11
    },
    'proplus': {
        'monthly': 380, 
        'yearly': 380*11
    }
  }

  const Heading = styled(Typography)(({ theme }) => ({
    textAlign: "left",
    fontWeight: 600,
    lineHeight: 1.1,
    margin: '0px 20px 0 20px',
    paddingBottom: '70px',
  }));

  const handleCompleteRegistration = async () => {
    window.location.href = '/?message=Successfully%20registered%2C%20check%20your%20email%20to%20confirm%20your%20email%20address.&success=true'
  }

  useEffect(() => {
    if(validateLoggedInUser() === 'Authorized')
      navigate('/')
  })

  return (
    
    <Box sx={{ 
      background: 'linear-gradient(0deg, rgba(245,245,245,1) 93%, rgba(233,255,222,1) 100%)', 
      padding: '0px', 
      flexGrow: 1,
      minHeight: '100vh'
    }}>
      <Box sx={{minHeight: 'calc(100vh - 50px)'}}>
      <AppBar sx={{boxShadow: 'none', backgroundColor: 'inherit'}} position="static">
        <Toolbar>
          <span onClick={() => {navigate("/")}} style={{cursor: 'pointer'}} item xs={6} sm={6} md={4} lg={4} xl={4}>
            <img alt='' style={{height: '25px', verticalAlign: 'middle', marginRight: '10px'}} src='img/logo.png'></img>
            <Typography variant="h6" component="span" sx={{ fontWeight: '400', fontSize: '17px', flexGrow: 1, color: '#40434E' }}>
              Login
            </Typography>
            <Typography variant="h6" component="span" sx={{ fontWeight: '700', fontSize: '17px', flexGrow: 1, color: '#40434E' }}>
              Register
            </Typography>
          </span>
        </Toolbar>
      </AppBar>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <Box sx={{padding: '50px 0 0 0', width: '100%', margin: 'auto'}}>
            <header>
              <Heading sx={{fontSize: {xs: '35px', sm: '35px', md: '55px', lg: '55px', xl: '55px'}, textAlign: {xs: 'center', sm:'center', md:'left', lg:'left', xl:'left'}, paddingBottom: '50px'}}>{props.heading}</Heading>
            </header>

            <section id='about'>
              <Typography sx={{margin: '0px 20px 0 20px', textAlign: {xs: 'center', sm:'center', md:'left', lg:'left', xl:'left'}, paddingBottom: '40px', fontSize: '20px'}}>{props.description}</Typography>
            </section>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Box sx={{maxWidth: '350px', margin: '0px auto 20px auto', backgroundColor: 'white', border: '1px solid black', filter: 'drop-shadow(0px 0px 16px #00000055)'}}>
            <Registration handleCompleteRegistration={handleCompleteRegistration} raiseAlert={raiseAlert} selectedPrice={selectedPrice} setSelectedPrice={setSelectedPrice} pricing={pricing} />
          </Box>
        </Grid>
      </Grid>
      </Box>
      <Box sx={{paddingLeft: '50px', paddingTop: '15px', color: 'grey', fontSize: '12px', backgroundColor: 'white', height: '50px'}}>
          &#169; 2024 Actionable KPIs, Inc. All rights reserved. 
        </Box>
    </Box>
  )
}

export default LandingPage;
