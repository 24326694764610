import { Stack } from '@mui/material';

function AlertStack(props) {
    return (
        <Stack style={{zIndex: '99999', position: 'absolute', width: '400px', right: '10px', top: '10px'}} spacing={2}>
            {props.alerts.map(function(d, idx){
                return (d)
            })}
        </Stack>
    )
}

export default AlertStack;