import { Box } from '@mui/material';
import {
  DataGrid,
  GridToolbar
} from '@mui/x-data-grid';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ReportGrid(props) {
  const columns = [
    {
      field: 'firstname',
      headerName: 'First Name',
      width: 200,
      editable: false,
      renderCell: ({ row }) => (
        <>
          <Link to={'/people/'+row.peopleid} style={{color: 'black'}}>{row.firstname}</Link>
        </>
      ),
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      width: 200,
      editable: false,
      renderCell: ({ row }) => (
        <>
          <Link to={'/people/'+row.peopleid} style={{color: 'black'}}>{row.lastname}</Link>
        </>
      ),
    },
    {
      field: 'accountname',
      headerName: 'Account Name',
      width: 300,
      editable: false,
    },
    {
      field: 'idpname',
      headerName: 'Account IDP System',
      width: 300,
      editable: false,
      renderCell: ({ row }) => (
        <>
          <Link to={'/system/'+row.idpsystemid} style={{color: 'black'}}>{row.idpname}</Link>
        </>
      ),
    },
    {
      field: 'accesslevel',
      headerName: 'Access Level',
      width: 300,
      editable: false,
    },
    {
      field: 'accessmethod',
      headerName: 'Access Method',
      width: 300,
      editable: false,
    },
    {
      field: 'systemname',
      headerName: 'System Name',
      width: 300,
      editable: false,
      renderCell: ({ row }) => (
        <>
          <Link to={'/system/'+row.systemid} style={{color: 'black'}}>{row.systemname}</Link>
        </>
      ),
    },
  ];

  if (props.isLoading) {
    return (
      <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={props.rows}
        columns={columns}
        rowHeight={25}
        slots={{ toolbar: GridToolbar }}
        disableDensitySelector
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: { disableToolbarButton: true }
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'firstname', sort: 'asc' }],
          },
          pagination: { paginationModel: { pageSize: 25 } },

        }}
      />
    </Box>
  );
}