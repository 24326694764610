import { useState, useEffect, useRef } from 'react'
import { useOutletContext, useParams, useNavigate, Link } from 'react-router-dom'
import { CircularProgress, Grid, Typography, Box, Button, Divider  } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import IdentityProviderDetailForm from '../components/IdentityProviderDetail/IdentityProviderDetailForm';
import IdentityProviderOwnedAccountsGrid from '../components/IdentityProviderDetail/IdentityProviderOwnedAccountsGrid';
import { validateLoggedInUser } from '../components/Generic/ValidateLoggedInUser';

function IdentityProviderDetail(props) {
  const raiseAlert = useOutletContext();
  const navigate = useNavigate();
  let { id } = useParams(); 

  const [isLoadingSystem, setIsLoadingSystem] = useState(true)
  const [isLoadingPeople, setIsLoadingPeople] = useState(true)
  const [isLoadingOwnedAccounts, setIsLoadingOwnedAccounts] = useState(true)
  const [peopleFullNames, setPeopleFullNames] = useState([])

  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [ownerID, setOwnerID] = useState('');
  const [ownedAccountRows, setOwnedAccountRows] = useState([]);

  const [selectedOwnedAccount, setSelectedOwnedAccount] = useState(null);
  const [ownedAccountFormOpen, setOwnedAccountFormOpen] = useState(false)

  const handleOwnedAccountEditOpen = (openVal, selectedID) => {
    if (selectedID === -1) {
      
    }
    else {
      const rowEdit = ownedAccountRows.find((row) => row.id === selectedID);
      
    }
    setOwnedAccountFormOpen(openVal)
  }

  useEffect(() => {
    const loadPage = async () => {
      const validationStatus = await validateLoggedInUser();
      if (validationStatus === 'Unauthorized') {
        fetch(process.env.REACT_APP_API_URL+'/api/logout', {
          method: 'post',
          }).then(res => {
            window.location.href = '/'
          }).catch(function (error) {
            window.location.href = '/'
        });
      } else if (validationStatus === 'TrialExpired') {
        navigate('/subscriptionexpired');
      } else {
        fetch(process.env.REACT_APP_API_URL+'/api/systems/'+id)
          .then((response) => {
            if(!response.ok)
                return false
            else
                return response.json()
          })
          .then((response) => {
            if(response) {
              if(response.error) {
                  raiseAlert('error', response.error);
              } else {
                  setName(response.data.name)
                  setNotes(response.data.notes)
                  setOwnerID(response.data.ownerpeopleid)
              }
            } else {
                raiseAlert('error', 'Error loading system');
            }
            setIsLoadingSystem(false)
          })
          .catch(function (error) {
            raiseAlert('error', 'Error loading system')
            setIsLoadingSystem(false)
          });


        fetch(process.env.REACT_APP_API_URL+'/api/systems/'+id+'/ownedaccounts')
          .then((response) => {
            if(!response.ok)
                return false
            else
                return response.json()
          })
          .then((response) => {
            if(response) {
              if(response.error) {
                  raiseAlert('error', response.error);
              } else {
                  setOwnedAccountRows(response.data)
              }
            } else {
                raiseAlert('error', 'Error loading owned accounts');
            }
            
            setIsLoadingOwnedAccounts(false)
          })
          .catch(function (error) {
            raiseAlert('error', 'Error loading owned accounts')
            setIsLoadingOwnedAccounts(false)
          });

        fetch(process.env.REACT_APP_API_URL+'/api/people')
          .then((response) => {
            if(!response.ok)
                return false
            else
                return response.json()
          })
          .then((response) => {
            if(response) {
              if(response.error) {
                  raiseAlert('error', response.error);
              } else {
                  const sorted_array = response.data.sort((a, b) => (a.fullname.toUpperCase() > b.fullname.toUpperCase()) ? 1 : -1)
                  setPeopleFullNames(sorted_array.map(function(d, idx){
                    return ({label: d.fullname, value: d.id})
                  }))
              }
            } else {
                raiseAlert('error', 'Error loading employees');
            }
            
            setIsLoadingPeople(false)
          }, [])
          .catch(function (error) {
            raiseAlert('error', 'Error loading employees')
            setIsLoadingPeople(false)
          });
      }
    }
    loadPage();
  }, [])


  if (isLoadingSystem || isLoadingPeople || isLoadingOwnedAccounts) {
    return (
      <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
        <Typography variant="h5" component="div" sx={{}}><AssignmentIndIcon sx={{verticalAlign: 'middle', marginRight: '20px'}} />Identity Provider: {name}</Typography>
        <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Manage a specific identity provider</Typography>
        <Box sx={{p: 1, paddingBottom: '0px' }}>
          <Grid container spacing={1}>
            <IdentityProviderDetailForm 
              raiseAlert={raiseAlert}
              name={name}
              notes={notes}
              ownerID={ownerID} 
              setName={setName}
              setNotes={setNotes}
              setOwnerID={setOwnerID}
              peopleFullNames={peopleFullNames}
              id={id}
              />
          </Grid>
        </Box>
        <Box>
          <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Owned Accounts</Typography>
          <IdentityProviderOwnedAccountsGrid
            rows={ownedAccountRows}
            setRows={setOwnedAccountRows}
            raiseAlert={raiseAlert}
            selectedRow={selectedOwnedAccount}
            setSelectedRow={setSelectedOwnedAccount}
            setEditOpen={handleOwnedAccountEditOpen}
              
          />
        </Box>
      </Box>
    )
  }
}


export default IdentityProviderDetail;
