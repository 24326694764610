import { useEffect, useState, useRef } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom';
import { CircularProgress, TextField, Button, Box, MenuItem, Typography, Grid  } from '@mui/material';
import { Network } from "vis-network";
import SchemaIcon from '@mui/icons-material/Schema';
import GenericConfirm from '../components/Generic/GenericConfirm';
import { validateLoggedInUser } from '../components/Generic/ValidateLoggedInUser';

function NetworkChart() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSystemFilterLoading, setIsSystemFilterLoading] = useState(true);
  const [isPeopleFilterLoading, setIsPeopleFilterLoading] = useState(true);
  const [selectedSystem, setSelectedSystem] = useState(-1);
  const [selectedPeople, setSelectedPeople] = useState(-1);
  const [data, setData] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [systems, setSystems] = useState([]);
  const [people, setPeople] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const domNode = useRef(null);
  const navigate = useNavigate();
  const raiseAlert = useOutletContext();

  // A reference to the vis network instance
  const network = useRef(null);

  function doubleClickNode(id) {
    if(id.startsWith('system_')) {
      let system_id = id.replace('system_', '')
      navigate('/system/'+system_id)
    }
    if(id.startsWith('person_')) {
      let people_id = id.replace('person_', '')
      navigate('/people/'+people_id)
    }
  }

  function doubleClickEdge(id) {

  }

  const options = {
    autoResize: true,
    height: '100%',
    width: '100%',
    layout: {
      improvedLayout: false,
      hierarchical: {
        enabled: false
      }
    },
    groups: {
      account: {
        color: {
          background:'#f180a3', 
          border: '#40434E',
          highlight: {
            border: '#40434E',
            background: '#f180a3'
          },
        }, 
        borderWidth: 1,
        shape: 'box'
      },
      system: {
        color: {
          background:'#99D17B', 
          border: '#40434E',
          highlight: {
            border: '#40434E',
            background: '#99D17B'
          },
        }, 
        borderWidth: 1,
        shape: 'box'
      },
      person: {
        color: {
          background:'#A7C6DA', 
          border: '#40434E',
          highlight: {
            border: '#40434E',
            background: '#A7C6DA'
          },
        }, 
        borderWidth: 1,
        shape: 'box'
      }
    }
  };

  function drawChart(draw_data) {
    network.current = new Network(domNode.current, draw_data, options);

    network.current.once("afterDrawing", function(properties) {
      setIsLoading(false);
    })

    network.current.on("doubleClick", function(properties) {
      if (properties.nodes.length > 0) {
        doubleClickNode(properties.nodes[0])
      }
      if (properties.edges.length > 0) {
        doubleClickEdge(properties.edges[0])
      }
    });
  }

  function getNetworkData() {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URL+'/api/network_chart_data?people_id='+selectedPeople+'&system_id='+selectedSystem)
    .then((response) => {
      if(response.ok)
        return response.json()
      else
        return false
    })
    .then((response) => {
      if(response) {
        if(response.error) {
          raiseAlert('error', response.error);
        } else {
          setData(response.data);
          setNodes(response.data.nodes)
          
          if(response.data.nodes.length >= 100) {
            setConfirmOpen(true)
          } else {
            drawChart(response.data)
          }
        }
      } else {
          raiseAlert('error', 'Error loading network chart');
      }
      setIsLoading(false)
    })
    .catch(function (error) {
      setIsLoading(false)
      raiseAlert('error', 'Error loading network chart')
    });
  }

  function getFilterData() {
    fetch(process.env.REACT_APP_API_URL+'/api/systems')
      .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
      }).then((response) => {
        if(response) {
          if(response.error) {
              raiseAlert('error', response.error);
          } else {
              const sorted_array = response.data.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : -1)
              setSystems(sorted_array.map(function(d, idx){
                return ({label: d.name, value: d.id})
              }))
          }
        } else {
            raiseAlert('error', 'Error loading filter values');
        }
        setIsSystemFilterLoading(false)
      }).catch((e) => {
        setIsSystemFilterLoading(false)
        raiseAlert('error', 'Error loading filter values')
      })
    
      fetch(process.env.REACT_APP_API_URL+'/api/people')
      .then((response) => {
        if(!response.ok)
            return false
        else
            return response.json()
      })
      .then((response) => {
        if(response) {
          if(response.error) {
              raiseAlert('error', response.error);
          } else {
            const sorted_array = response.data.sort((a, b) => (a.fullname.toUpperCase() > b.fullname.toUpperCase()) ? 1 : -1)
            setPeople(sorted_array.map(function(d, idx){
              return ({label: d.fullname, value: d.id})
            }))
          }
        } else {
            raiseAlert('error', 'Error loading filter values');
        }
        setIsPeopleFilterLoading(false)
      }, []).catch(() => {
        setIsPeopleFilterLoading(false)
        raiseAlert('error', 'Error loading filter values')
      })
  }

  function clickRun() {
    document.getElementById('graph_report').innerHTML = "";
    getNetworkData()
  }

  function handleCloseCancel() {
    setNodes([])
    setConfirmOpen(false)
    setIsLoading(false)
  }

  function handleCloseOK() {
    setConfirmOpen(false)
    drawChart(data)
  }

  useEffect(() => {
    const loadPage = async () => {
      const validationStatus = await validateLoggedInUser();
      if (validationStatus === 'Unauthorized') {
        fetch(process.env.REACT_APP_API_URL+'/api/logout', {
          method: 'post',
          }).then(res => {
            window.location.href = '/'
          }).catch(function (error) {
            window.location.href = '/'
        });
      } else if (validationStatus === 'TrialExpired') {
        navigate('/subscriptionexpired');
      } else {
        getFilterData();
      }
    };
    loadPage();
  }, [])

  return (
    <>
      <GenericConfirm handleCloseOK={handleCloseOK} handleCloseCancel={handleCloseCancel} open={confirmOpen} title='Render Chart?' text="The filters you've selected include large amounts of data. This may take some time to render, are you sure you want to continue?"  />
      <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
        <Typography variant="h5" component="div" sx={{}}><SchemaIcon sx={{verticalAlign: 'middle', marginRight: '20px'}}/>Network Chart</Typography>
        <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Visualize your enterprise</Typography>
        <Box sx={{p: 1, }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              {isSystemFilterLoading && 
                <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                  <CircularProgress size={'30px'}/>
                </Box>
              }
              {!isSystemFilterLoading && 
                <TextField margin='none' size='small' style={{width: '100%'}} select value={selectedSystem} onChange={(event) => {setSelectedSystem(event.target.value)}} label="System" >
                  <MenuItem value='-1'>All Systems</MenuItem>
                  {Object.keys(systems).map((keyName, i) => (
                    <MenuItem value={systems[i].value}>{systems[i].label}</MenuItem>
                  ))}
                </TextField>
              }
                
              
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              {isPeopleFilterLoading && 
                <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                  <CircularProgress size={'30px'} />
                </Box>
              }
              {!isPeopleFilterLoading && 
                <TextField margin='none' size='small' style={{width: '100%'}} select value={selectedPeople} onChange={(event) => {setSelectedPeople(event.target.value)}} label="People" >
                  <MenuItem value='-1'>All People</MenuItem>
                  {Object.keys(people).map((keyName, i) => (
                    <MenuItem value={people[i].value}>{people[i].label}</MenuItem>
                  ))}
                </TextField>
              }
            </Grid>
            <Grid sx={{textAlign: 'center'}} item xs={12} sm={2} md={2} lg={2} xl={2}>
              <Button onClick={(event) => {clickRun()}} disabled={isLoading} sx={{ height: '40px', padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >
                Run
              </Button>
            </Grid>
            <Grid sx={{textAlign: 'center'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '4px', height: {xs: 'calc(100vh - 245px)', sm:'calc(100vh - 150px)', md:'calc(100vh - 150px)', lg:'calc(100vh - 150px)', xl: 'calc(100vh - 150px)'}, width: '100%'}}>
                {isLoading && 
                  <CircularProgress sx={{marginTop: '30vh'}} />
                }
                {(!isLoading && nodes.length === 0) && 
                  <Typography component="div" sx={{marginTop: '5px', fontWeight: '200', fontSize: '0.8rem'}}>Click Run above to generate the Network Chart</Typography>
                }
                <div id='graph_report' style={{height: '100%', width: '100%'}} ref = { domNode }></div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default NetworkChart;
