import { useState } from 'react'
import { Box, Button } from '@mui/material';import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import GenericConfirm from '../Generic/GenericConfirm';

function EditToolbar(props) {
  const { setEditOpen, setSelectedSystem } = props;

  const handleClick = () => {
    setEditOpen(true, -1)
    setSelectedSystem(-1)
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Create an account
      </Button>
    </GridToolbarContainer>
  );
}

export default function IdentityProviderOwnedAccountsGrid(props) {
  const [deletingPopup, setDeletingPopup] = useState({show: false, id: null, name: null});

  const handleCellDoubleClick = (params, event) => {
    props.setSelectedRow(params.id);
    props.setEditOpen(true, params.id)
  }

  const handleEditClick = (id) => () => {
    props.setSelectedRow(id);
    props.setEditOpen(true, id)
  };

  const handleDeleteClick = (id) => () => {
    const deletingRow = props.rows.find((row) => row.id === id);
    setDeletingPopup({
      show: true,
      id: deletingRow.id,
      name: deletingRow.accountname
    })
  };

  const columns = [
    {
      field: 'accountname',
      headerName: 'Account Name',
      width: 350,
      editable: false,
    },
    {
      field: 'created',
      headerName: 'Created',
      width: 280,
      editable: false,
    },
    {
      field: 'updated',
      headerName: 'Updated',
      width: 280,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleCloseConfirmDeleteConfirm = () => {
    fetch(process.env.REACT_APP_API_URL+'/api/access_level?access_level_id='+deletingPopup.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if(!response.ok)
          return false
      else
          return response.json()
    }).then((response) => {
      if(response) {
        if(response.error) {
          props.raiseAlert('error', response.error);
        } else {
          props.setRows(props.rows.filter((row) => row.id !== deletingPopup.id));

          setDeletingPopup({
            show: false,
            id: '',
            name: ''
          })

          props.raiseAlert('success', 'Deleted record');
        }
      } else {
        props.raiseAlert('error', 'Unable to delete record');
      }
    })
    .catch(function(error) {
      props.raiseAlert('error', 'Unable to delete record');
    })
  };
    
  const handleCloseConfirmDeleteCancel = () => {
    setDeletingPopup({
      show: false,
      id: '',
      name: ''
    })
  };

  
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={props.rows}
        columns={columns}
        rowHeight={25}
        onCellDoubleClick={handleCellDoubleClick}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setEditOpen: props.setEditOpen, setSelectedSystem: props.setSelectedRow },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              accountname: true,
              created: false,
              updated: false,
              actions: true
            },
          },
          pagination: { paginationModel: { pageSize: 25 } },
        }}
      />
      <GenericConfirm 
        open={deletingPopup.show} 
        handleClose={handleCloseConfirmDeleteCancel} 
        title="Confirm delete"
        text={<>Confirm delete of access level <b>{deletingPopup.name}</b>?</>}
        handleCloseOK={handleCloseConfirmDeleteConfirm}
        handleCloseCancel={handleCloseConfirmDeleteCancel}
        />

    </Box>
  );
}