import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const PageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    //console.log('Logging page view for', currentPath);
    ReactGA.send({ hitType: "pageview", page: currentPath });
  }, [location.pathname, location.search]);

  return null;
};

export default PageTracking;