import { useState, useEffect } from 'react'
import { useOutletContext, useParams, useNavigate } from 'react-router-dom'
import { Box, Typography, CircularProgress, Grid } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import PeopleDetailForm from '../components/PeopleDetail/PeopleDetailForm';
import { validateLoggedInUser } from '../components/Generic/ValidateLoggedInUser';

function PeopleDetail(props) {
  const raiseAlert = useOutletContext();
  const navigate = useNavigate();

  let { id } = useParams(); 

  //const [isLoadingSystem, setIsLoadingSystem] = useState(true)
  const [isLoadingPeople, setIsLoadingPeople] = useState(true)
  //const [isLoadingAccessMethods, setIsLoadingAccessMethods] = useState(true)
  //const [isLoadingAccessLevels, setIsLoadingAccessLevels] = useState(true)
  //const [peopleFullNames, setPeopleFullNames] = useState([])

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  //const [accessMethodRows, setAccessMethodRows] = useState([]);
  //const [accessLevelRows, setAccessLevelRows] = useState([]);

  //const [selectedAccessMethod, setSelectedAccessMethod] = useState(null);
  //const [accessMethodName, setAccessMethodName] = useState(null)
  //const [accessMethodFormOpen, setAccessMethodFormOpen] = useState(false)
  //const [selectedAccessLevel, setSelectedAccessLevel] = useState(null);
  //const [accessLevelName, setAccessLevelName] = useState(null)
  //const [accessLevelFormOpen, setAccessLevelFormOpen] = useState(false)

  /*const handleAccessLevelEditOpen = (openVal, selectedID) => {
    if (selectedID === -1) {
      setAccessLevelName('')
    }
    else {
      const rowEdit = accessLevelRows.find((row) => row.id === selectedID);
      setAccessLevelName(rowEdit.accesslevel)
    }
    setAccessLevelFormOpen(openVal)
  }

  const handleAccessMethodEditOpen = (openVal, selectedID) => {
    if (selectedID === -1) {
      setAccessMethodName('')
    }
    else {
      const rowEdit = accessMethodRows.find((row) => row.id === selectedID);
      setAccessMethodName(rowEdit.accessmethod)
    }
    setAccessMethodFormOpen(openVal)
  }
  */

  useEffect(() => {
    const loadPage = async () => {
      const validationStatus = await validateLoggedInUser();
      if (validationStatus === 'Unauthorized') {
        fetch(process.env.REACT_APP_API_URL+'/api/logout', {
          method: 'post',
          }).then(res => {
            window.location.href = '/'
          }).catch(function (error) {
            window.location.href = '/'
        });
      } else if (validationStatus === 'TrialExpired') {
        navigate('/subscriptionexpired');
      } else {
        fetch(process.env.REACT_APP_API_URL+'/api/people/'+id)
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
                setFirstName(response.data.firstname)
                setLastName(response.data.lastname)
                setEmail(response.data.email)
            }
          } else {
              raiseAlert('error', 'Error loading employee');
          }

          setIsLoadingPeople(false)
        })
        .catch(function (error) {
          raiseAlert('error', 'Error loading employee')
          setIsLoadingPeople(false)
        });

      /*fetch(process.env.REACT_APP_API_URL+'/api/access_methods?system_id='+id)
        .then((res) => {
          if(res.ok)
            return res.json()
          else
            return false
        })
        .then((data) => {
          setAccessMethodRows(data.data)
          setIsLoadingAccessMethods(false)
        })
        .catch(function (error) {
          raiseAlert('error', 'Error loading access methods')
          setIsLoadingAccessMethods(false)
        });

      fetch(process.env.REACT_APP_API_URL+'/api/access_levels?system_id='+id)
        .then((res) => {
          if(res.ok)
            return res.json()
          else
            return false
        })
        .then((data) => {
          setAccessLevelRows(data.data)
          setIsLoadingAccessLevels(false)
        })
        .catch(function (error) {
          raiseAlert('error', 'Error loading access levels')
          setIsLoadingAccessLevels(false)
        });
      
      fetch(process.env.REACT_APP_API_URL+'/api/people')
        .then((res) => {
          if(res.ok)
            return res.json()
          else {
            return {data: []}
          }
        })
        .then((data) => {
          setPeopleFullNames(data.data.map(function(d, idx){
            return ({label: d.fullname, value: d.id})
          }))
          setIsLoadingPeople(false)
        }, [])
        .catch(function (error) {
          raiseAlert('error', 'Error loading employees')
          setIsLoadingPeople(false)
        });
        */
      }
    };
    loadPage();
  }, [])

  useEffect(() => {
    if(validateLoggedInUser() === 'Unauthorized')
      navigate('/')
    else {

      }
     
  }, [])
  /*
  function handleCompleteSaveAccessLevel() {
    setAccessLevelName(null)
    setAccessLevelFormOpen(false)
  }

  function handleCompleteSaveAccessMethod() {
    setAccessMethodName(null)
    setAccessMethodFormOpen(false)
  }

  function openAccessMethod() {
    
  }

  function openAccessLevel() {
    
  }
  */

  if (isLoadingPeople) { // || isLoadingAccessMethods || isLoadingAccessLevels) {
    return (
      <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
        <Typography variant="h5" component="div" sx={{}}><StorageIcon sx={{verticalAlign: 'middle', marginRight: '20px'}} />Employee: {firstName} {lastName}</Typography>
        <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Edit a specific employee</Typography>
        <Box sx={{p: 1, }}>
          <Grid container spacing={1}>
            <PeopleDetailForm 
              raiseAlert={raiseAlert}
              firstName={firstName}
              lastName={lastName}
              email={email} 
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              id={id}
              />

          </Grid>
        </Box>
      </Box>
    )
  }
  
}


export default PeopleDetail;
