import { useState } from 'react'
import { Link } from 'react-router-dom';
import { Box, TextField, Checkbox, MenuItem, FormGroup, FormControlLabel, Button, Typography, CircularProgress } from '@mui/material';

function Registration(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [companyNameError, setCompanyNameError] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [agreeTermsError, setAgreeTermsError] = useState(null);

    function validateForm(field) {
        var anyError = false;
        if (field === 'firstName') {
            if (firstName.length <= 1) {
                setFirstNameError('First name must be at least two characters')
                anyError = true;
            }
            else
                setFirstNameError(null)
        }
        if (field === 'lastName') {
            if (lastName.length <= 1) {
                setLastNameError('Last name must be at least two characters')
                anyError = true;
            }
            else
                setLastNameError(null)
        }
        if (field === 'companyName') {
            if (companyName.length <= 1) {
                setCompanyNameError('Company name must be at least two characters')
                anyError = true;
            }
            else
                setCompanyNameError(null)
        }
        if (field === 'email') {
            var validEmailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (email.match(validEmailRegex)) {
                setEmailError(null)
            } else {
                setEmailError('Invalid email address')
                anyError = true;
            }
        }
        if (field === 'password') {
            var validPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
            if (password.match(validPasswordRegex)) {
                setPasswordError(null)
            } else {
                setPasswordError('Password must contain at least 8 characters, including one letter, one number and one special character')
                anyError = true;
            }
        }
        if (field === 'agreeTerms') {
            if (agreeTerms)
                setAgreeTermsError(null)
            else {
                setAgreeTermsError('error') // No message for agree terms error
                anyError = true;
            }
        }
        return anyError;
    }

    function clickRegister() {
        setIsLoading(true)
        let firstNameErrorCheck = validateForm('firstName');
        let lastNameErrorCheck = validateForm('lastName');
        let companyNameErrorCheck = validateForm('companyName');
        let emailErrorCheck = validateForm('email');
        let passwordErrorCheck = validateForm('password');
        let agreeTermsErrorCheck = validateForm('agreeTerms');
        
        if (firstNameErrorCheck || lastNameErrorCheck || companyNameErrorCheck || emailErrorCheck || passwordErrorCheck || agreeTermsErrorCheck) {
            setIsLoading(false)
            return false;
        }
        else {
            const post_data = {
                'email': email,
                'password': password,
                'product': props.selectedPrice,
                'first_name': firstName,
                'last_name': lastName,
                'company_name': companyName
            }
            const post_headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            fetch(process.env.REACT_APP_API_URL+'/api/register', {
                method: 'post',
                body: new URLSearchParams(post_data),
                headers: post_headers
            }).then((response) => {
                if(!response.ok)
                    return false
                else
                    return response.json()
            }).then((response) => {
                if (response) {
                    if (response.error)
                        props.raiseAlert('error', response.error)
                    else 
                        props.handleCompleteRegistration()
                } else {
                    props.raiseAlert('error', 'Error during signup')
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                props.raiseAlert('error', 'Error during signup')
                setIsLoading(false)
            }); 
        }
    }

    return (
        <>
            {isLoading && 
                <div style={{position: 'fixed', paddingTop: '300px', display: 'flex', justifyContent: 'center', top: 0, left: 0, zIndex: 999, width: '100%', height: '100%', background: '#646464aa'}}>
                    <CircularProgress sx={{}} />
                </div>
            }
            <Box sx={{p: 2}}>
                <h2 style={{marginBottom: '0px'}}>Free Trial Signup</h2>
                <Typography sx={{marginBottom: '0px', fontWeight: '200'}}>No credit card required.</Typography>
                <TextField value={firstName} onChange={(event) => {setFirstName(event.target.value)}} onBlur={(event) => {validateForm('firstName')}} margin="normal" required fullWidth id="outlined-basic" label="First Name" variant="outlined" error={!!firstNameError} helperText={firstNameError} />
                <TextField value={lastName} onChange={(event) => {setLastName(event.target.value)}} onBlur={(event) => {validateForm('lastName')}} margin="normal" required fullWidth id="outlined-basic" label="Last Name" variant="outlined" error={!!lastNameError} helperText={lastNameError}  />
                <TextField value={companyName} onChange={(event) => {setCompanyName(event.target.value)}} onBlur={(event) => {validateForm('companyName')}} margin="normal" required fullWidth id="outlined-basic" label="Company Name" variant="outlined" error={!!companyNameError} helperText={companyNameError}  />
                <TextField required fullWidth margin='normal' select value={props.selectedPrice} onChange={(event) => {props.setSelectedPrice(event.target.value)}} label="Company Size" >
                    <MenuItem value={'basic'}>1-9 Employees</MenuItem>
                    <MenuItem value={'standard'}>10-49 Employees</MenuItem>
                    <MenuItem value={'pro'}>50-99 Employees</MenuItem>
                    <MenuItem value={'proplus'}>100-249 Employees</MenuItem>
                    <MenuItem value={'enterprise'}>250+ Employees</MenuItem>
                </TextField>
                {
                    props.selectedPrice === 'enterprise' &&
                    <Typography sx={{fontSize: '12px', fontWeight: 200}}>14 day free trial, then bespoke pricing</Typography>
                }
                {
                    props.selectedPrice !== 'enterprise' &&
                    <Typography sx={{fontSize: '12px', fontWeight: 200}}>14 day free trial, then ${props.pricing[props.selectedPrice].yearly} Yearly or ${props.pricing[props.selectedPrice].monthly} Monthly</Typography>
                }
                
                <TextField value={email} onChange={(event) => {setEmail(event.target.value)}} onBlur={(event) => {validateForm('email')}} margin="normal" required fullWidth id="outlined-basic" label="Email" variant="outlined" error={emailError} helperText={!!emailError} />
                <TextField value={password} onChange={(event) => {setPassword(event.target.value)}} onBlur={(event) => {validateForm('password')}} type="password" margin="normal" required fullWidth id="outlined-basic" label="Password" variant="outlined" error={!!passwordError} helperText={passwordError} />
                <FormGroup>
                    <FormControlLabel style={{color: !!agreeTermsError ? "red" : ""}} control={<Checkbox checked={agreeTerms} onChange={(event) => {setAgreeTerms(event.target.checked)}} onBlur={(event) => {validateForm('agreeTerms')}} style={{color: !!agreeTermsError ? "red" : ""}} />} label={<>Agree to <Link style={{color: !!agreeTermsError ? "red" : ""}} target='_blank' to='legal'>terms and conditions</Link></>} />
                </FormGroup>

                <Button onClick={(event) => {clickRegister()}} sx={{ width: '100%', padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >SIGN UP FOR A 14 DAY FREE TRIAL NOW</Button>
            </Box>
        </>
    )
}

export default Registration;
