import { useState } from 'react'
import { Box, Button } from '@mui/material';
import GenericConfirm from '../Generic/GenericConfirm';
import { DataGrid, GridToolbarContainer, GridActionsCellItem, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CircularProgress } from '@mui/material';
import { Link, useNavigate} from 'react-router-dom';


function EditToolbar(props) {
  const { setEditOpen, setselectedRow } = props;

  const handleClick = () => {
    setEditOpen(true, -1)
    setselectedRow(-1)
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

export default function PeopleGrid(props) {
  const [deletingPopup, setDeletingPopup] = useState({show: false, id: null, name: null});
  const navigate = useNavigate();

  const handleCellDoubleClick = (params, event) => {
    navigate("/people/"+params.id);
  }

  const handleEditClick = (id) => () => {
    navigate("/people/"+id);
  };

  const handleDeleteClick = (id) => () => {
    const deletingRow = props.rows.find((row) => row.id === id);
    setDeletingPopup({
      show: true,
      id: deletingRow.id,
      name: deletingRow.firstname + ' ' + deletingRow.lastname
    })
  };

  const columns = [
    {
      field: 'firstname',
      headerName: 'First Name',
      width: 200,
      editable: false,
      renderCell: ({ row }) => (
        <>
          <Link to={'/people/'+row.id} style={{color: 'black'}}>{row.firstname}</Link>
        </>
      ),
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      width: 200,
      editable: false,
      renderCell: ({ row }) => (
        <>
          <Link to={'/people/'+row.id} style={{color: 'black'}}>{row.lastname}</Link>
        </>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: false,
    },
    {
      field: 'created_at',
      headerName: 'Created',
      width: 280,
      editable: false,
    },
    {
      field: 'updated_at',
      headerName: 'Updated',
      width: 280,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      width: 80,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleCloseConfirmDeleteConfirm = () => {
    fetch(process.env.REACT_APP_API_URL+'/api/people/'+deletingPopup.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if(!response.ok)
          return false
      else
          return response.json()
      }).then((response) => {
        if(response) {
          if(response.error) {
              props.raiseAlert('error', response.error);
          } else {
            props.setRows(props.rows.filter((row) => row.id !== deletingPopup.id));

            setDeletingPopup({
              show: false,
              id: '',
              name: ''
            })
            props.raiseAlert('success', 'Deleted record');
          }
        } else {
          props.raiseAlert('error', 'Error deleting record');
        }
      }).catch(function (error) {
        props.raiseAlert('error', 'Error deleting record')
      });

  };
    
  const handleCloseConfirmDeleteCancel = () => {
    setDeletingPopup({
      show: false,
      id: '',
      name: ''
    })
  };

  if (props.isLoading) {
    return (
      <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={props.rows}
        columns={columns}
        rowHeight={25}
        onCellDoubleClick={handleCellDoubleClick}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setEditOpen: props.setEditOpen, setselectedRow: props.setselectedRow },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'firstname', sort: 'asc' }],
          },
          columns: {
            columnVisibilityModel: {
              firstname: true,
              lastname: true,
              email: true,
              created_at: false,
              updated_at: false,
              actions: true
            },
          },
          pagination: { paginationModel: { pageSize: 25 } },

        }}
      />
      <GenericConfirm 
        open={deletingPopup.show} 
        handleClose={handleCloseConfirmDeleteCancel} 
        title="Confirm delete"
        text={<>Confirm delete of employee <b>{deletingPopup.name}</b>?</>}
        handleCloseOK={handleCloseConfirmDeleteConfirm}
        handleCloseCancel={handleCloseConfirmDeleteCancel}
        />
    </Box>
  );
}