import { useState } from 'react'
import { Box, Modal, TextField, Button, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Login(props) {
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
      };
    
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(null);

    function validateForm(field) {
        var anyError = false;
        if (field === 'username') {
            if (username.length <= 1) {
                setUsernameError('Enter an email address')
                anyError = true;
            }
            else
            setUsernameError(null)
        }
        if (field === 'password') {
            if (password.length <= 1) {
                setPasswordError('Please enter a password')
                anyError = true;
            }
            else
            setPasswordError(null)
        }
        
        return anyError;
    }

    function clickForgottenPassword() {
        props.handleForgottonPassword()
    }

    function handleLoginClick(event) {
        let usernameErrorCheck = validateForm('username');
        let passwordErrorCheck = validateForm('password');

        if (!usernameErrorCheck && !passwordErrorCheck) {
            const post_data = {
                'username': username,
                'password': password
            }
            const post_headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            
            fetch(process.env.REACT_APP_API_URL+'/api/login', {
                method: 'post',
                body: new URLSearchParams(post_data),
                headers: post_headers
                }).then((response) => {
                    if(!response.ok)
                        return false
                    else
                        return response.json()
                }).then((response) => {
                    if(response) {
                        if(response.error) {
                            props.raiseAlert('error', response.error);
                        } else {
                            window.location.href = '/'
                        }
                    } else {
                        props.raiseAlert('error', 'Error logging in');
                    }
                })
                .catch(function (error) {
                    props.raiseAlert('error', 'Error logging in')
                });
        }
    };

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
            >
                <Box component="form" sx={style} >
                    <div style={{float: 'right'}}><Button sx={{minWidth: '30px'}} onClick={props.handleClose}><CloseIcon/></Button></div>
                    <Box sx={{p: 2}}>
                        <h2>Login</h2>
                        <TextField name="username" id="username" value={username} onChange={(event) => {setUsername(event.target.value); validateForm('username');}} onBlur={(event) => {validateForm('username')}} margin="normal" required fullWidth label="Email" variant="outlined" error={!!usernameError} helperText={usernameError} />
                        <TextField name="password" id="password" type="password" value={password} onChange={(event) => {setPassword(event.target.value)}} onBlur={(event) => {validateForm('password')}} margin="normal" required fullWidth label="Password" variant="outlined" error={!!passwordError} helperText={passwordError}  />
                        <Box sx={{marginTop: '15px', display: 'flex',}}>
                            <Box><Button onClick={handleLoginClick} sx={{ padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >Login</Button></Box>
                            <Box sx={{flexGrow: 1}}></Box>
                            <Box sx={{marginTop: '8px', cursor: 'pointer'}}><Link onClick={(event) => {clickForgottenPassword()}} sx={{ }} >Forgot Password</Link></Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default Login;
