'use client'
import { Link } from 'react-router-dom';
import { Box, Divider, Drawer, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Toolbar, Typography, Tooltip } from '@mui/material';
import Cookies from 'js-cookie';
import StorageIcon from '@mui/icons-material/Storage';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import SchemaIcon from '@mui/icons-material/Schema';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import GradingIcon from '@mui/icons-material/Grading'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { isAdmin } from '../Generic/ValidateLoggedInUser';

const drawerWidth = 240;
const drawerWidthSmall = 58;

function Navbar(props) {
  const handleLogoutClick = () => {
    fetch(process.env.REACT_APP_API_URL+'/api/logout', {
      method: 'post',
      }).then(res => {
        window.location.href = '/'
      }).catch(function (error) {
        window.location.href = '/'
    });
    
  };

  if ((Cookies.get('auth0Accesstoken') && Cookies.get('auth0Accesstoken') !== "")) {
    return (
      <>
      <Box
          component="nav"
          sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, width: drawerWidth}}
        >
        <Box
          component="nav"
          sx={{ width: drawerWidth, flexGrow: 1}}
        >
          <Drawer
            variant="permanent"
            sx={{flexGrow: 1,
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
              <Toolbar>
                <Link to='/' style={{color: 'black', textDecoration: 'none'}}>
                  <img alt='' style={{height: '25px', verticalAlign: 'middle', marginRight: '10px'}} src='img/logo.png'></img>
                  <Typography variant="h6" component="span" sx={{ fontWeight: '400', fontSize: '17px', flexGrow: 1, color: '#40434E' }}>
                    Login
                  </Typography>
                  <Typography variant="h6" component="span" sx={{ fontWeight: '700', fontSize: '17px', flexGrow: 1, color: '#40434E' }}>
                    Register
                  </Typography>
                </Link>
              </Toolbar>
              <Divider />
              <List sx={{paddingBottom: '0px', paddingTop: '0px'}}>
                  <ListItem key={'nav_systems'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/systems'>
                      <ListItemIcon>
                        <StorageIcon/>
                      </ListItemIcon>
                      <ListItemText primary='Systems' secondary='All Systems in the organization' />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key={'nav_idpsystems'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/identityproviders'>
                      <ListItemIcon>
                        <AssignmentIndIcon/>
                      </ListItemIcon>
                      <ListItemText primary='Identity Providers' secondary='All Identity Providers' />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key={'nav_people'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/people'>
                      <ListItemIcon>
                        <EmojiPeopleIcon/>
                      </ListItemIcon>
                      <ListItemText primary='Employees' secondary='All Employees in the organization' />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem key={'nav_networkchart'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/networkchart'>
                      <ListItemIcon>
                        <SchemaIcon/>
                      </ListItemIcon>
                      <ListItemText primary='Network Chart' secondary='Visualize your enterprise' />
                    </ListItemButton>
                  </ListItem>
                  <ListItem key={'nav_reports'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/reports'>
                      <ListItemIcon>
                        <EqualizerIcon />
                      </ListItemIcon>
                      <ListItemText primary='Reports' secondary='Report on your enterprise' />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
              </List>
              <List sx={{paddingTop: '0px', marginTop: 'auto'}} >
                <Divider />
                  <ListItem sx={{display: (isAdmin() ? 'block' : 'none'),}} key={'nav_audit'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/audit'>
                      <ListItemIcon>
                        <GradingIcon/>
                      </ListItemIcon>
                      <ListItemText primary='Audit Log' secondary='Report of all actions taken' />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                <ListItem key={'nav_settings'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/settings'>
                      <ListItemIcon>
                        <SettingsIcon/>
                      </ListItemIcon>
                      <ListItemText primary='Account Settings' secondary='Manage your settings' />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem sx={{color: '#c80606'}} key={'nav_people'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='#'
                      onClick={handleLogoutClick}
                    >
                      <ListItemIcon>
                        <LogoutIcon sx={{color: '#c80606'}}/>
                      </ListItemIcon>
                      <ListItemText sx={{fontSize: '14px',}} primary='Logout' />
                    </ListItemButton>
                  </ListItem>
              </List>
          </Drawer>
        </Box>
      </Box>
      <Box
          component="nav"
          sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: drawerWidthSmall }}
        >
        <Box
          component="nav"
          sx={{ width: drawerWidthSmall, flexGrow: 1}}
        >
          <Drawer
            variant="permanent"
            sx={{flexGrow: 1,
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSmall },
            }}
            open
          >
              <Toolbar sx={{paddingLeft: '13px !important', height: '64px !important'}}>
                <Link to='/' style={{color: 'black', textDecoration: 'none'}}>
                  <Tooltip title="Home" placement="right" arrow>
                    <img alt='' style={{height: '25px', verticalAlign: 'middle', marginRight: '10px'}} src='img/logo.png'></img>
                  </Tooltip>
                </Link>
              </Toolbar>
              <Divider />
              <List>
                  <ListItem key={'nav_systems'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/systems'>
                      <ListItemIcon>
                        <Tooltip title="Systems" placement="right" arrow>
                          <StorageIcon/>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem key={'nav_idpsystems'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/identityproviders'>
                      <ListItemIcon>
                        <Tooltip title="Identity Providers" placement="right" arrow>
                          <AssignmentIndIcon/>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem key={'nav_people'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/people'>
                      <ListItemIcon>
                        <Tooltip title="Employees" placement="right" arrow>
                          <EmojiPeopleIcon/>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem key={'nav_networkchart'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/networkchart'>
                      <ListItemIcon>
                        <Tooltip title="Network Chart" placement="right" arrow>
                          <SchemaIcon/>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <ListItem key={'nav_reports'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/reports'>
                      <ListItemIcon>
                        <Tooltip title="Reports" placement="right" arrow>
                          <EqualizerIcon />
                        </Tooltip>
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
              </List>
              <List style={{ marginTop: `auto` }} >
                <Divider />
                  <ListItem sx={{display: (isAdmin() ? 'block' : 'none'),}} key={'nav_audit'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/audit'>
                      <ListItemIcon>
                        <Tooltip title="Audit Log" placement="right" arrow>
                          <GradingIcon/>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                <Divider />
                <ListItem key={'nav_settings'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='/settings'>
                      <ListItemIcon>
                        <Tooltip title="Settings" placement="right" arrow>
                          <SettingsIcon/>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem sx={{color: '#c80606'}} key={'nav_people'} disablePadding>
                    <ListItemButton LinkComponent={Link} to='#'
                      onClick={handleLogoutClick}
                    >
                      <ListItemIcon>
                        <Tooltip title="Logout" placement="right" arrow>
                          <LogoutIcon sx={{color: '#c80606'}}/>
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText sx={{fontSize: '14px',}}/>
                    </ListItemButton>
                  </ListItem>
              </List>
          </Drawer>
        </Box>
      </Box>
      </>
      
    );
  }
}

export default Navbar;