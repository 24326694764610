import { useState } from 'react'
import { Box, Modal, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function AccessMethodForm(props) {
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
      };
    
    const [nameError, setNameError] = useState(null);
    
    function validateForm(field) {
        var anyError = false;
        if (field === 'name') {
            if (props.name.length <= 1) {
                setNameError('Access Method name must be at least one characters')
                anyError = true;
            }
            else
                setNameError(null)
        }

        return anyError;
    }

    function clickSave() {
        
        let nameErrorCheck = validateForm('name');
         
        if (nameErrorCheck) {
            props.raiseAlert('error', 'Error whilst saving record')
            return false;
        }
        else {
            const post_data = {
                'id': props.selectedId,
                'system_id': props.systemId,
                'accessmethod': props.name
            }
            
            const post_headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

            fetch(process.env.REACT_APP_API_URL+'/api/access_method', {
                method: 'put',
                body: new URLSearchParams(post_data),
                headers: post_headers
                }).then((response) => {
                    if(!response.ok)
                        return false
                    else
                        return response.json()
                }).then((response) => {
                    if(response) {
                        if(response.error) {
                            props.raiseAlert('error', response.error);
                        } else {
                            const newRow = response.data
                            if (props.selectedId === -1) {
                                props.setRows((oldRows) => [...oldRows, newRow]);
                                props.raiseAlert('success', 'Created new access method')
                            }
                            else {
                                props.setRows(props.rows.map((row) => (row.id === props.selectedId ? newRow : row)));
                                props.raiseAlert('success', 'Updated access method')
                            }
                            props.handleCompleteSave()
                        } 
                    } else {
                        props.raiseAlert('error', 'Error saving access method');
                    }
                })
                .catch(function (error) {
                    props.raiseAlert('error', 'Error saving access method')
                });
        }
    }

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
            >
                <Box component="form" sx={style} >
                    <div style={{float: 'right'}}><Button sx={{minWidth: '30px'}} onClick={props.handleClose}><CloseIcon/></Button></div>
                    <Box sx={{p: 2}}>
                        <h2>{(props.selectedId === -1 ? 'New' : 'Edit')} Access Method</h2>
                        <TextField value={props.name} onChange={(event) => {props.setName(event.target.value)}} onBlur={(event) => {validateForm('name')}} margin="normal" required fullWidth id="outlined-basic" label="Access Method" variant="outlined" error={!!nameError} helperText={nameError} />
                        <Button onClick={(event) => {clickSave()}} disabled={!!nameError} sx={{ padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >Save</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default AccessMethodForm;
