import LandingPage from "../components/Home/LandingPage";

export function LandingPageSpreadsheets() {
  return (
    <LandingPage heading='Stop relying on spreadsheets and best endeavours.' description={<>Login<span style={{fontWeight: 500}}>Register</span> vastly simplifies the challenges associated with User Access Management, by providing an innovative and flexible online portal for Adminstrators or Quality Managers to records Users, Systems and who has access to what.</>} />
  )
}

export function LandingPageModern() {
  return (
    <LandingPage heading='Modern user access management' description={<>Login<span style={{fontWeight: 500}}>Register</span> vastly simplifies the challenges associated with User Access Management, by providing an innovative and flexible online portal for Adminstrators or Quality Managers to records Users, Systems and who has access to what.</>} />
  )
}

export function LandingPageISO() {
  return (
    <LandingPage heading='Wondering how best to audit user access for ISO27001?' description={<>Login<span style={{fontWeight: 500}}>Register</span> vastly simplifies the challenges associated with User Access Management, by providing an innovative and flexible online portal for Adminstrators or Quality Managers to records Users, Systems and who has access to what.</>} />
  )
}

export function LandingPageACM() {
  return (
    <LandingPage heading='Is your access control matrix driving you mad?' description={<>Login<span style={{fontWeight: 500}}>Register</span> vastly simplifies the challenges associated with User Access Management, by providing an innovative and flexible online portal for Adminstrators or Quality Managers to records Users, Systems and who has access to what.</>} />
  )
}