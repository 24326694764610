import { useState } from 'react'
import { useNavigate} from 'react-router-dom';
import { CircularProgress, TextField, Button, Grid, Tooltip  } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function PeopleDetailForm(props) {
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  
  function validateForm(field) {
    var anyError = false;
    if (field === 'firstName') {
      if (props.firstName.length <= 1) {
        setFirstNameError('First name must be at least two characters')
        anyError = true;
      }
      else {
        setFirstNameError(null)
      }
    }

    if (field === 'lastName') {
      if (props.lastName.length <= 1) {
        setLastNameError('Last name must be at least two characters')
        anyError = true;
      }
      else {
        setLastNameError(null)
      }
    }

    if (field === 'email') {
        var validEmailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (props.email.match(validEmailRegex)) {
            setEmailError(null)
        } else {
            setEmailError('Invalid email address')
            anyError = true;
        }
    }

    return anyError;
  }

  function clickBack() {
    navigate('/people')
  }

  function clickSave() {
    setIsSaving(true);
    validateForm('firstName');
    validateForm('lastName');
    validateForm('email');
    
    if (firstNameError || lastNameError || emailError) {
      props.raiseAlert('error', 'Error whilst saving record')
      setIsSaving(false);
      return false;
    }
    else {
      const post_data = {
        'id': props.id,
        'firstname': props.firstName,
        'lastname': props.lastName,
        'email': props.email
      }
        
      const post_headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }

      fetch(process.env.REACT_APP_API_URL+'/api/people', {
        method: 'put',
        body: new URLSearchParams(post_data),
        headers: post_headers
      }).then((response) => {
        if(!response.ok)
            return false
        else
            return response.json()
      }).then((response) => {
        if(response) {
          if(response.error) {
            props.raiseAlert('error', response.error);
          } else {
            props.raiseAlert('success', 'Updated Employee')
          }
        } else {
          props.raiseAlert('error', 'Error updating employee');
        }
        setIsSaving(false);
      }).catch(function (error) {
        props.raiseAlert('error', 'Error updating employee');
        setIsSaving(false);
      });
    }
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Tooltip title='Back'>
          <Button onClick={(event) => {clickBack()}} sx={{minWidth: '32px'}} size='small'>
            <ArrowBackIcon sx={{color: '#40434E'}}/>
          </Button>
        </Tooltip>
        <Tooltip title='Save'>
          <Button disabled={isSaving} onClick={(event) => {clickSave()}} sx={{minWidth: '32px'}} size='small'>
            {isSaving ? <CircularProgress size={'16px'} /> : <SaveIcon />}
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextField margin='normal' size='small' value={props.firstName} onChange={(event) => {props.setFirstName(event.target.value)}} onBlur={(event) => {validateForm('firstName')}} required fullWidth id="outlined-basic" label="First Name" variant="outlined" error={!!firstNameError} helperText={firstNameError} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextField margin='normal' size='small' value={props.lastName} onChange={(event) => {props.setLastName(event.target.value)}} onBlur={(event) => {validateForm('lastName')}} required fullWidth id="outlined-basic" label="Last Name" variant="outlined" error={!!lastNameError} helperText={lastNameError} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextField margin='normal' size='small' value={props.email} onChange={(event) => {props.setEmail(event.target.value)}} onBlur={(event) => {validateForm('email')}} required fullWidth id="outlined-basic" label="Email Name" variant="outlined" error={!!emailError} helperText={emailError} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}