import { useState, useEffect } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import SystemsGrid from '../components/Systems/SystemsGrid.js'
import SystemsForm from '../components/Systems/SystemsForm.js';
import GenericConfirm from '../components/Generic/GenericConfirm.js';
import { validateLoggedInUser } from '../components/Generic/ValidateLoggedInUser.js';

function Systems() {
  const raiseAlert = useOutletContext();
  const [editOpen, setEditOpen] = useState(false);
  const [editConfirmationOpen, setEditConfirmationOpen] = useState(false);
  const [selectedSystem, setSelectedSystem] = useState(-1);
  const [isLoading, setLoading] = useState(true)
  const [rows, setRows] = useState([]);
  const [peopleFullNames, setPeopleFullNames] = useState([])

  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [ownerID, setOwnerID] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');  

  const navigate = useNavigate();
  
  const handleEditOpen = (openVal, selectedID) => {
    if (selectedID === -1) {
      setName('')
      setNotes('')
      setOwnerID('')
      setOwnerEmail('')
    }
    else {
      const rowEdit = rows.find((row) => row.id === selectedID);
      setName(rowEdit.name)
      setNotes(rowEdit.notes)
      setOwnerID(rowEdit.ownerpeopleid)
      setOwnerEmail(rowEdit.owneremail)
    }
    setEditOpen(openVal)
  }

  const handleFormClose = () => {
    setEditConfirmationOpen(true)
  };

  const handleCompleteSave = (id) => {
    setEditConfirmationOpen(false)
    setEditOpen(false)
    navigate("/system/"+id);
  };

  const handleFormConfirmationCloseCancel = (event) => {
    setEditConfirmationOpen(false)
  };

  const handleFormConfirmationCloseOK = (event) => {
    setEditConfirmationOpen(false)
    setEditOpen(false)
  };

  useEffect(() => {
    const loadPage = async () => {
      const validationStatus = await validateLoggedInUser();
      if (validationStatus === 'Unauthorized') {
        fetch(process.env.REACT_APP_API_URL+'/api/logout', {
          method: 'post',
          }).then(res => {
            window.location.href = '/'
          }).catch(function (error) {
            window.location.href = '/'
        });
      } else if (validationStatus === 'TrialExpired') {
        navigate('/subscriptionexpired');
      } else {
        fetch(process.env.REACT_APP_API_URL+'/api/systems')
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
                setRows(response.data)
            }
          } else {
              raiseAlert('error', 'Error loading systems');
          }
          setLoading(false)
        }).catch(function (error) {
            setLoading(false)
            raiseAlert('error', 'Error loading systems')
        });
      
        fetch(process.env.REACT_APP_API_URL+'/api/people')
        .then((response) => {
          if(!response.ok)
              return false
          else
              return response.json()
        })
        .then((response) => {
          if(response) {
            if(response.error) {
                raiseAlert('error', response.error);
            } else {
              const sorted_array = response.data.sort((a, b) => (a.fullname.toUpperCase() > b.fullname.toUpperCase()) ? 1 : -1)
              setPeopleFullNames(sorted_array.map(function(d, idx){
                return ({label: d.fullname, value: d.id})
              }))
            }
          } else {
              raiseAlert('error', 'Error loading employees');
          }
          
        }, [])
        .catch(function (error) {
            raiseAlert('error', 'Error loading employees')
        });
      }
    };
    loadPage();
  }, [])

  return (
    <Box sx={{p: {xs: 1, sm: 1, md: 2, lg: 2, xl: 3}, }}>
      <Typography variant="h5" component="div" sx={{}}><StorageIcon sx={{verticalAlign: 'middle', marginRight: '20px'}} />Systems</Typography>
      <Typography component="div" sx={{fontWeight: '200', fontSize: '0.8rem'}}>Manage the systems in your enterprise</Typography>
      <Box sx={{p: 1, }}>
        <SystemsForm 
          open={editOpen} 
          handleClose={handleFormClose} 
          handleCompleteSave={handleCompleteSave}
          selectedSystem={selectedSystem} 
          name={name}
          setName={setName}
          notes={notes}
          setNotes={setNotes}
          ownerID={ownerID}
          setOwnerID={setOwnerID}
          ownerEmail={ownerEmail}
          setOwnerEmail={setOwnerEmail}
          peopleFullNames={peopleFullNames}
          raiseAlert={raiseAlert}
          rows={rows}
          setRows={setRows}
          />
        <GenericConfirm title='Cancel changes?' text='Are you sure you want to cancel your changes?' handleCloseOK={handleFormConfirmationCloseOK} handleCloseCancel={handleFormConfirmationCloseCancel} open={editConfirmationOpen} />
        <SystemsGrid 
          rows={rows}
          setRows={setRows}
          raiseAlert={raiseAlert}
          editOpen={editOpen}
          setEditOpen={handleEditOpen}
          setSelectedSystem={setSelectedSystem}
          isLoading={isLoading}
          />
      </Box>
    </Box>
  )
}

export default Systems;
