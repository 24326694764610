import { useEffect, useState, useRef } from 'react'
import ReactGA from 'react-ga4';
import { AppBar, Box, Toolbar, Typography, Button, Grid } from '@mui/material';
import { styled } from "@mui/material/styles";
import Pricing from './Pricing';
import FAQ from './FAQ';
import RegistrationModal from './RegistrationModal';
import Login from './Login';
import ForgottenPassword from './ForgottenPassword';
import GenericConfirm from '../Generic/GenericConfirm'
import ProductInfoTile from './ProductInfoTile';

function HomeNotAuthed(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamMessage = urlParams.get('message');
  const urlParamSuccess = urlParams.get('success');

  const pricingRef = useRef(null);
  const aboutRef = useRef(null);
  const faqRef = useRef(null);
  const [registrationOpen, setRegistrationOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [forgottenPasswordOpen, setForgottenPasswordOpen] = useState(false);
  const [registrationConfirmationOpen, setRegistrationConfirmationOpen] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState('basic')

  const StyledButton = styled(Button)(({ theme, color = 'primary' }) => ({
    ':hover': {
      color: 'black',
      backgroundColor: '#e4e0e0',
    },
  }));

  const handleLoginClose = () => {
    setLoginOpen(false)
  };

  const handleForgottenPasswordClose = () => {
    setForgottenPasswordOpen(false)
  };

  const handleForgottenPasswordClick = () => {
    // Track the click event
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Forgotten Password Button'
    });    
    setForgottenPasswordOpen(true)
    setLoginOpen(false)
  };

  const handleRegistrationClose = () => {
    setRegistrationConfirmationOpen(true)
  };

  const handleRegistrationConfirmationCloseCancel = (event) => {
    setRegistrationConfirmationOpen(false)
  };

  const handleRegistrationConfirmationCloseOK = (event) => {
    // Track the event
    ReactGA.event({
      category: 'Cancel',
      action: 'Abandon',
      label: 'Abandon Registration'
    });
    setRegistrationConfirmationOpen(false)
    setRegistrationOpen(false)
  };

  const handlePricingSignupButtonClick = (event) => {
    let label = ''; 
    if (typeof event === 'undefined' || typeof event.target === 'undefined' || typeof event.target.id === 'undefined') {
      //clicked the primary 'Sign up for a 14 day free trial now' buttom
      label = 'Primary Call to Action - Trial'
    } else {
      console.log(event.target.id)
      //clicked a trial button from <Pricing />
      //split to first half of the id: plan_timeperiod e.g. pro_yearly
      setSelectedPrice(event.target.id.split('_')[0])
      label = 'Pricing Trial Button - ' + event.target.id
    }
    setRegistrationOpen(true)
    // Track the click event
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: label
    });
  }

  const handleLoginClick = async () => {
    // Track the click event
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Login Button'
    });
    setLoginOpen(true)
  }

  const handleCompleteRegistration = async () => {
    setRegistrationOpen(false)
    props.raiseAlert('warning', 'Successfully registered, check your email to confirm your email address.')
  }

  const pricing = {
    'basic': {
        'monthly': 35, 
        'yearly': 35*11
    },
    'standard': {
        'monthly': 90, 
        'yearly': 90*11
    },
    'pro': {
        'monthly': 160, 
        'yearly': 160*11
    },
    'proplus': {
        'monthly': 380, 
        'yearly': 380*11
    }
  }

  useEffect(() => {
    if (urlParamMessage && urlParamSuccess==='true') {
      props.raiseAlert('success', urlParamMessage)
    }
    if (urlParamMessage && urlParamSuccess==='false') {
      props.raiseAlert('error', urlParamMessage)
    }
  }, [urlParamMessage])

  const Heading = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: 1.1,
    marginLeft: "15%",
    marginRight: "15%",
    paddingBottom: '70px',
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
      paddingBottom: '20px',
    },
  }));

  const handleNavButtonClick = (event, ref, label) => {
    event.preventDefault();
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // Track the click event
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: label
    });
  };

  return (
    <Box sx={{ 
      background: 'linear-gradient(0deg, rgba(245,245,245,1) 93%, rgba(233,255,222,1) 100%)', 
      padding: '0px', 
      flexGrow: 1 
    }}>
      <RegistrationModal handleCompleteRegistration={handleCompleteRegistration} raiseAlert={props.raiseAlert} selectedPrice={selectedPrice} setSelectedPrice={setSelectedPrice} pricing={pricing} handleClose={handleRegistrationClose} open={registrationOpen} />
      <Login raiseAlert={props.raiseAlert} handleForgottonPassword={handleForgottenPasswordClick} handleClose={handleLoginClose} open={loginOpen} />
      <ForgottenPassword handleClose={handleForgottenPasswordClose} open={forgottenPasswordOpen} raiseAlert={props.raiseAlert} />
      <GenericConfirm title='Cancel registration?' text='Are you sure you want to cancel the registration for your free trial?' handleCloseOK={handleRegistrationConfirmationCloseOK} handleCloseCancel={handleRegistrationConfirmationCloseCancel} open={registrationConfirmationOpen} />
      <AppBar sx={{boxShadow: 'none', backgroundColor: 'inherit'}} position="static">
        <Toolbar>
          <Grid container>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <img alt='' style={{height: '25px', verticalAlign: 'middle', marginRight: '10px'}} src='img/logo.png'></img>
              <Typography variant="h6" component="span" sx={{ fontWeight: '400', fontSize: '17px', flexGrow: 1, color: '#40434E' }}>
                Login
              </Typography>
              <Typography variant="h6" component="span" sx={{ fontWeight: '700', fontSize: '17px', flexGrow: 1, color: '#40434E' }}>
                Register
              </Typography>
            </Grid>
            <Grid sx={{textAlign: 'center'}} display={{ xs: 'none', sm: 'none', md: 'inherit', lg:'inherit', xl:'inherit'}} item xs={0} sm={0} md={4} lg={4} xl={4} >
              <Typography variant="h6" component="span" sx={{ flexGrow: 1, color: '#40434E' }}>
                <Button style={{color: '#40434E', paddingLeft: '18px', paddingRight: '18px'}} href='#' onClick={(event) => handleNavButtonClick(event, aboutRef, 'About Button')}>About</Button>
                <Button style={{color: '#40434E', paddingLeft: '18px', paddingRight: '18px'}} href='#' onClick={(event) => handleNavButtonClick(event, pricingRef, 'Pricing Button')}>Pricing</Button>
                <Button style={{color: '#40434E', paddingLeft: '18px', paddingRight: '18px'}} href='#' onClick={(event) => handleNavButtonClick(event, faqRef, 'FAQ Button')}>FAQ</Button>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
              <Button onClick={handleLoginClick} sx={{ height: '30px', float: 'right', padding: '10px 25px 10px 25px', fontWeight: 'bold', backgroundColor: '#40434E', color: '#99D17B' }} >Login</Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box sx={{textAlign: 'center', p:3, }}>
        <Box sx={{paddingTop: '50px', paddingLeft: '50px', paddingRight: '50px', width: '100%', margin: 'auto'}}>
          <header>
            <Heading sx={{paddingBottom: '50px'}}>Seamless and secure management of your company logins and access permissions</Heading>
          </header>

          <section id='about'>
            <p style={{paddingBottom: '40px'}}>The solution for user account management and Cyber Security Compliance. Catalog systems and the people who have access, quickly see who can access what system, with which login. Sign up for a free trial today to enhance security and streamline access management.</p>
            <StyledButton sx={{ marginBottom: '60px', padding: '15px 35px 15px 35px', fontWeight: 'bold', backgroundColor: '#40434E', color: 'white' }} onClick={() => handlePricingSignupButtonClick()}>Sign up for a 14 day free trial now</StyledButton>
          </section>
        </Box>
        <Grid ref={aboutRef} container spacing={2} sx={{padding: {xs: '0px 20px 0px 20px',sm: '0px 50px 0px 50px',md: '0px 100px 0px 100px',lg: '0px 30px 0px 30px',xl:'0px 30px 0px 30px'}}}>
          <ProductInfoTile img='/img/home_iso.png' header='Work towards compliance standards' description="Whatever standard you are working towards, whether it's the UK's Cyber Essentials, ISO 27001, SOC2 or something else - the one thing they all require is proper management of user access. LoginRegister provides a centralized management platform, helping you remain compliant." />
          <ProductInfoTile img='/img/home_computer.png' header='Manage joiners, movers and leavers' description={<>Manage the risks associated with Joiners, Movers and Leavers, ensuring access to <b>all</b> systems is revoked when no longer required. Should the worst happen, and one of your Employees' accounts is compromised, get instant information on what systems and data the attacker had access to.</>} />
          <ProductInfoTile img='/img/home_team.png' header='Flexibility built-in' description="Spreadsheets quickly become unwieldy, hard to maintain and limited in the data they can sensibly include. LoginRegister has been designed to be flexible, offering support for regular user accounts, service accounts, shared accounts, identity providers/SSO solutions and custom access levels." />
          <ProductInfoTile img='/img/home_networkchart.png' header='Visualize your organization in a Network Chart' description="Graph-based interface to easily find users and the systems they have access to, how they access them and their access level. Traverse your organization quickly, drilling down to Systems and Employees directly from the graph." />
          <ProductInfoTile img='/img/home_export.png' header='Customizable reports out of the box' description="Instantly generate versatile reports tailored to individuals or systems, showcasing user-specific access or system-specific permissions, enabling efficient access management and oversight." />
          <ProductInfoTile img='/img/home_audit.png' header='Full audit tracking' description='LoginRegister keeps a record of any changes made to Systems, Employees and associated Accounts. Quickly see who made modifications and when they were made.' />
        </Grid>
        <Box ref={pricingRef} sx={{paddingTop: '30px', width: '100%', margin: 'auto'}}>
          <Box sx={{marginTop: '20px'}}>
            <Pricing StyledButton={StyledButton} handlePricingSignupButtonClick={handlePricingSignupButtonClick} rawPricing={pricing}/>
          </Box>
          <Box ref={faqRef} sx={{marginTop: '50px'}}>
            <FAQ />
          </Box>
        </Box>
      </Box>
      <Box sx={{paddingLeft: '50px', paddingTop: '15px', color: 'grey', fontSize: '12px', backgroundColor: 'white', height: '50px'}}>
        &#169; 2024 Actionable KPIs, Inc. All rights reserved. 
      </Box>
    </Box>
  )
}

export default HomeNotAuthed;
