import { useState } from 'react'
import { Box, Button, CircularProgress } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import GenericConfirm from '../../Generic/GenericConfirm';

function EditToolbar(props) {
  const { setEditOpen, setselectedRow } = props;

  const handleClick = () => {
    setEditOpen(true, -1)
    setselectedRow(-1)
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export default function UsersGrid(props) {
  const [deletingPopup, setDeletingPopup] = useState({show: false, id: null, name: null});

  const handleCellDoubleClick = (params, event) => {
    props.setselectedRow(params.id)
    props.setEditOpen(true, params.id)
  }

  const handleEditClick = (id) => () => {
    props.setselectedRow(id)
    props.setEditOpen(true, id)
  };

  const handleInactivateClick = (id) => () => {
    const deletingRow = props.rows.find((row) => row.id === id);
    setDeletingPopup({
      show: true,
      id: deletingRow.id,
      name: deletingRow.given_name + ' ' + deletingRow.family_name
    })
  };

  const columns = [
    {
      field: 'given_name',
      headerName: 'First Name',
      width: 200,
      editable: false,
    },
    {
      field: 'family_name',
      headerName: 'Last Name',
      width: 200,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: false,
    },
    {
      field: 'email_verified',
      headerName: 'Email Verified',
      width: 130,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {(row.email_verified === true ? <CheckCircleIcon sx={{ color: '#3e7a3e' }} /> : <CancelIcon sx={{ color: '#7a3e3e' }} />)}
        </>
      ),
      align: 'center'
    },
    {
      field: 'blocked',
      headerName: 'Active',
      width: 80,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {(row.blocked === false ? <CheckCircleIcon sx={{ color: '#3e7a3e' }} /> : <CancelIcon sx={{ color: '#7a3e3e' }} />)}
        </>
      ),
      align: 'center'
    },
    {
      field: 'app_metadata.loginregister_admin',
      headerName: 'Admin',
      width: 80,
      editable: false,
      renderCell: ({ row }) => (
        <>
          {(row.app_metadata.loginregister_admin === true ? <CheckCircleIcon sx={{ color: '#3e7a3e' }} /> : <CancelIcon sx={{ color: '#7a3e3e' }} />)}
        </>
      ),
      align: 'center'
    },
    {
      field: 'created_at',
      headerName: 'Created',
      width: 280,
      editable: false,
    },
    {
      field: 'updated_at',
      headerName: 'Updated',
      width: 280,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      width: 80,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        ];
      },
    },
  ];

  const handleCloseConfirmDeleteConfirm = () => {
    fetch(process.env.REACT_APP_API_URL+'/api/users?user_id='+deletingPopup.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if(!response.ok)
          return false
      else
          return response.json()
    }).then((response) => {
      if(response) {
        if(response.error) {
            props.raiseAlert('error', response.error);
        } else {
          props.setRows(props.rows.filter((row) => row.id !== deletingPopup.id));

          setDeletingPopup({
            show: false,
            id: '',
            name: ''
          })
    
          props.raiseAlert('success', 'Deleted record');
        }
      } else {
        props.raiseAlert('error', 'Error deleting record');
      }
    }).catch(function (error) {
      props.raiseAlert('error', 'Error deleting record')
    });

  };
    
  const handleCloseConfirmDeleteCancel = () => {
    setDeletingPopup({
      show: false,
      id: '',
      name: ''
    })
  };

  if (props.isLoading) {
    return (
      <Box sx={{ justifyContent: 'center', marginTop: '100px', display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={props.rows}
        columns={columns}
        rowHeight={25}
        onCellDoubleClick={handleCellDoubleClick}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setEditOpen: props.setEditOpen, setselectedRow: props.setselectedRow },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'family_name', sort: 'asc' }],
          },
          columns: {
            columnVisibilityModel: {
              given_name: true,
              family_name: true,
              created_at: false,
              updated_at: false,
              actions: true
            },
          },
          pagination: { paginationModel: { pageSize: 25 } },
        }}
      />
      <GenericConfirm 
        open={deletingPopup.show} 
        handleClose={handleCloseConfirmDeleteCancel} 
        title="Confirm delete"
        text={<>Confirm inactivation of user <b>{deletingPopup.name}</b>?</>}
        handleCloseOK={handleCloseConfirmDeleteConfirm}
        handleCloseCancel={handleCloseConfirmDeleteCancel}
        />
      
    </Box>
  );
}