import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

export const validateLoggedInUser = async function() {
    try {
        if(!jwtDecode(Cookies.get('auth0IDtoken')).email)
            return 'Unauthorized'
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/subscription')
        if(!response.ok)
            return 'Unauthorized';
        const responseData = await response.json();
        if(responseData.error)
            return 'Unauthorized';
        switch(responseData.data.subscription.status) {
            case 'trialing':
            case 'active':
                return 'Authorized'
            case 'incomplete':
            case 'incomplete_expired':
            case 'past_due':
            case 'canceled':
            case 'unpaid':
            default:
                return 'TrialExpired';
        }
    } catch (e) {
        return 'Unauthorized';
    }
}

export const isAdmin = function () {
    try {
        return jwtDecode(Cookies.get('auth0IDtoken'))['https://loginregister.com/app_metadata'].loginregister_admin;
    } catch (e) {
        return false;
    }
}